import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { phone, helpMsg } from '../../../constants.js';

export default function NeedHelp({ setNeedHelp }) {
  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
        width: '100%',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        marginTop={2}
        marginBottom={8}
      >
        <Typography variant="h3">Need Help?</Typography>

        <Typography variant="body1" color={'grey'}>
          For creating new account or any help regarding your existing account,
          please contact us on WhatsApp.
        </Typography>
        <Box>
          <Button
            variant="contained"
            size="large"
            sx={{
              width: '100%',
              marginBottom: '10px',
              cursor: 'pointer',
            }}
            href={`https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
              helpMsg
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Get help on WhatsApp
          </Button>
          <Typography variant="body2" textAlign="right" color="primary.main">
            <Link
              component={'span'}
              underline="none"
              sx={{ cursor: 'pointer' }}
              onClick={() => setNeedHelp(false)}
            >
              Know your password
            </Link>
          </Typography>
        </Box>
      </Box>
    </form>
  );
}
