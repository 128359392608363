//@ts-check

import React, { useState } from "react";
import { PageStyle } from "../../components/PageStyle";
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Skeleton,
  Stack,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  AddAlert,
  Diversity1,
  FestivalRounded,
  Person,
  StackedLineChart,
  Whatshot,
} from "@mui/icons-material";
import { useEffect } from "react";
import { customFetch } from "../../util/axios";
import authHeader from "../../util/authToken";
import { toast } from "react-toastify";
import IOSSwitch from "../components/customStyled/IOSSwitch";
import RectangularAvatar from "../components/customStyled/RectangularAvatar";
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from "../components/customStyled/customAccordion";
import { green, orange, teal } from "@mui/material/colors";
import { cronToDate } from "../../util/dateFunction";

export default function PresetCampaignPage() {
  const [personal, setPersonal] = useState([]);
  const [festival, setFestival] = useState([]);
  const [specialDay, setSpecialDay] = useState([]);
  const [campaignStatus, setCampaignStatus] = useState({});
  const [changedCampaignStatus, setChangedCampaignStatus] = useState({});
  const [moreCampaigns, setMoreCampaigns] = useState([]);
  const [reqInProgress, setReqInProgress] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();

  useEffect(() => {
    customFetch
      .get("/api/v1/preset_camp", authHeader())
      .then((resForCmpgn) => {
        customFetch
          .get("/api/v1/preset_camp/status", authHeader())
          .then((resForStatus) => {
            //seting campaing status before campaign to avoid
            //error: a component is changing uncontrolled to controlled
            setCampaignStatus(resForStatus.data.presetCampaignsStatus);

            resForCmpgn.data.forEach(campaign => {
              campaign.date = cronToDate(campaign.occurence);
            });

            setPersonal(
              resForCmpgn.data.filter(
                (campaign) =>
                  String(campaign.category).toLowerCase() === "personal"
              ).sort((a, b) => a.date - b.date)
            );
            setFestival(
              resForCmpgn.data.filter(
                (campaign) =>
                  String(campaign.category).toLowerCase() === "festival"
              ).sort((a, b) => a.date - b.date)
            );
            setSpecialDay(
              resForCmpgn.data.filter(
                (campaign) =>
                  String(campaign.category).toLowerCase() === "specialdays"
              ).sort((a, b) => a.date - b.date)
            );
            setIsLoading(false)
          })
          .catch((err) => {
            console.error("unable to fetch preset campaign status", err);
          });
      })
      .catch((err) => {
        console.error("unable to fatch preset campaign", err);
      });

      customFetch
        .get("/api/v1/preset_camp/more", authHeader())
        .then((res) => {
          setMoreCampaigns(res.data);
        })
        .catch((err) => {
          console.error("unable to fetch more campaigns", err);
        })
  }, []);

  const handleAccordionChange = (accordionName) => (event, isExpended) => {
    setExpandedAccordion(isExpended ? accordionName : "");
  };

  const handleCampaignStatusChange = (campaignId) => (event) => {
    if (campaignId in changedCampaignStatus) {
      delete changedCampaignStatus[campaignId];
      setChangedCampaignStatus({ ...changedCampaignStatus });
    } else {
      changedCampaignStatus[campaignId] = event.target.checked;
      setChangedCampaignStatus({ ...changedCampaignStatus });
    }
  };

  const saveChangedCampignStatus = async () => {
    try {
      if (!Object.keys(changedCampaignStatus).length) {
        toast.info("There in no change in campaign state", {
          position: "bottom-left",
        });
        return;
      }
      setReqInProgress(true);

      await customFetch.patch(
        "/api/v1/preset_camp/user",
        {
          changedCampaignStatus: changedCampaignStatus,
        },
        authHeader()
      );

      toast.success("Campaign state updated sucessfully", {
        position: "bottom-left",
      });
      setCampaignStatus({ ...campaignStatus, ...changedCampaignStatus });
      setChangedCampaignStatus({});
    } catch (err) {
      console.error("unable to update campign status", err);
      toast.error("Unable to update campaign state", {
        position: "bottom-left",
      });
    } finally {
      setReqInProgress(false);
    }
  };

  function countActiveCampaign(campaignData) {
    if(!campaignData) return 1;
    let count = 0;
    campaignData.forEach((campaign) => {
      campaignStatus[campaign._id] && count++;
    });

    return count;
  }

  // Templates begins
  function accordionDetailsTemplate(data) {
    return data.map((row, i) => (
      <React.Fragment key={i}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="1rem 0rem"
          gap={10}
        >
          <Stack direction="row" gap={'5px'} alignItems="center">
            <Typography fontWeight='bold'>{`${row.name}`}</Typography>
            <Typography>
              {`(${row.date? new Date(row.date).toLocaleDateString('en-In', {
                dateStyle: "medium"
              }) : ''})`}
            </Typography>
          </Stack>
          <IOSSwitch
            checked={
              row._id in changedCampaignStatus
                ? changedCampaignStatus[row._id]
                : campaignStatus[row._id]
            }
            onChange={handleCampaignStatusChange(row._id)}
            name="loading"
            color="primary"
          />
        </Box>
        {i < data.length - 1 ? <Divider light /> : null}
      </React.Fragment>
    ));
  }

  function accordionSummaryTemplate({
    Icon,
    heading,
    description,
    campaignData,
  }) {
    return (
      <Box
        gap={2}
        display="flex"
        alignItems="center"
        width="100%"
        flexDirection={{
          sm: "row",
          xs: "column",
        }}
      >
        <RectangularAvatar
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Icon />
        </RectangularAvatar>
        <Stack
          direction={{
            md: "row",
            sx: "column",
          }}
          justifyContent="space-between"
          width="100%"
          alignItems={{
            md: "center",
          }}
          gap={1}
        >
          <Box>
            <Typography variant="body1" fontWeight={700}>
              {heading}
            </Typography>
            <Typography variant="body2" color="gray">
              {description}
            </Typography>
          </Box>

      
          {isLoading ? (
            // Render skeleton loading placeholder
            <Skeleton variant="rectangular" width={100} height={30} />
          ) : (
            // Render the actual Chip component when not loading
            <Chip
              label={campaignData ? `Active: ${countActiveCampaign(campaignData)}` : 'Active'}
              sx={{
                color: countActiveCampaign(campaignData)
                  ? teal.A700
                  : orange["900"],
                backgroundColor: countActiveCampaign(campaignData)
                  ? green.A100
                  : orange.A100,
                height: "30px",
                width: "100px",
                display: "flex",
                borderRadius: "5px",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
                marginRight: (theme) => theme.spacing(1),
              }}
            />
          )}
        </Stack>
      </Box>
    );
  }

  function AccordionTemplate({
    onExpand,
    expandedAccordion,
    icon,
    heading,
    description,
    campaignData,
  }) {
    return (
      <CustomAccordion
        expanded={expandedAccordion === heading}
        onChange={onExpand(heading)}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            visibility: campaignData ? 'initial' : 'hidden',
          }
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          {accordionSummaryTemplate({
            Icon: icon,
            heading,
            description,
            campaignData,
          })}
        </CustomAccordionSummary>
        {campaignData &&

        <CustomAccordionDetails>
          {accordionDetailsTemplate(campaignData)}
        </CustomAccordionDetails>
        }
      </CustomAccordion>
    );
  }

  const isStateChanged = !!Object.keys(changedCampaignStatus).length;
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: "0px",
          transform: `translateY(${isStateChanged ? "0px" : "calc(-250px)"})`,
          height: `${isStateChanged ? "70px" : "0px"}`,
          pointerEvents: isStateChanged ? "auto" : "none",
          boxShadow: "0px 0px 10px 3px rgba(145, 158, 171, 0.2)",
          backgroundColor: "white",
          borderRadius: "16px",
          zIndex: 1,
          transition: "150ms ease",
          flexWrap: "wrap",
        }}
      >
        <Alert
          severity="info"
          sx={{
            borderRadius: "16px",
            border: "1px solid rgba(145, 158, 171, 0.25)",
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            "& .MuiAlert-action": {
              padding: "0",
              marginRight: "0",
            },
          }}
          action={
            <Box display="flex" gap="0.5rem" alignItems="center">
              <Button
                variant="outlined"
                color="error"
                onClick={() => setChangedCampaignStatus({})}
                tabIndex={isStateChanged ? 1 : -1}
                disabled={reqInProgress}
              >
                Discard
              </Button>
              <Button
                variant="contained"
                sx={{
                  // borderRadius: '100rem',
                  color: "white",
                  width: "100px",
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
                onClick={saveChangedCampignStatus}
                tabIndex={isStateChanged ? 1 : -1}
                disabled={reqInProgress}
              >
                {reqInProgress ? "Saving ..." : "Save"}
              </Button>
            </Box>
          }
        >
          <Typography color={"inherit"}>
            Do you want to save your cahanges?
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          margin: {
            md: "3rem 5rem",
            // sm: '(theme) => theme.spacing(2)',
            xs: "3rem 0.1rem",
          },
        }}
      >
        <AccordionTemplate
          expandedAccordion={expandedAccordion}
          onExpand={handleAccordionChange}
          icon={Person}
          heading="Personal"
          description="Heartfelt Wishes: Personalized Birthday and Anniversary Messages for Your Customers."
          campaignData={personal}
        />
        <AccordionTemplate
          expandedAccordion={expandedAccordion}
          onExpand={handleAccordionChange}
          icon={FestivalRounded}
          heading="Festival"
          description="Festive Delights: Campaigns for Popular Indian Festivals - Holi, Diwali, Rakhi, and More."
          campaignData={festival}
        />
        <AccordionTemplate
          expandedAccordion={expandedAccordion}
          onExpand={handleAccordionChange}
          icon={Diversity1}
          heading="Special Days"
          description="Special Occasions: Campaigns for Celebrating Popular Days - Mother's Day, Father's Day, Friendship Day, and More."
          campaignData={specialDay}
        />

        <Divider/>

        {moreCampaigns.map((campaigns, i) =>
          <AccordionTemplate
            expandedAccordion=''
            onExpand={handleAccordionChange}
            icon={getIcon(campaigns.icon)}
            heading={campaigns.name}
            description={campaigns.description}
            campaignData={''}
            key={i}
          />
        )}
        {/* <AccordionTemplate
          expandedAccordion={false}
          onExpand={handleAccordionChange}
          icon={Whatshot}
          heading="Motivational Messages"
          description="Message for motivating your customer"
        />
        <AccordionTemplate
          expandedAccordion={false}
          onExpand={handleAccordionChange}
          icon={StackedLineChart}
          heading="Stock Market Update"
          description="Give update about stock market to your customer"
        />
        <AccordionTemplate
          expandedAccordion={false}
          onExpand={handleAccordionChange}
          icon={AddAlert}
          heading="Announcement"
          description="A gental reminder for tax to your customer"
        /> */}
      </Box>
    </>
  );
}


function getIcon(iconName){
  let icon = Whatshot;
  switch(iconName.toLowerCase()) {
    case('addalert'): {
      icon = AddAlert;
      break;
    }
    case('stackedlinechart'): {
      icon = StackedLineChart;
      break;
    }
  }

  return icon;
}