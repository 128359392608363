

import React, { useEffect } from "react";
import { PageStyle } from "../../components/PageStyle";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import socket, { connectSocketForWwebjs } from "../../util/socket";
import { resetWwebStatus } from "../../slice/wwebSlice";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";


export default function ConnectToWhatsApp() {
  const dispatch = useDispatch();
  const wwebjs = useSelector((store) => store.wweb);
  const { user, isLoading } = useSelector((store) => store.user);
  useEffect(() => {
    !wwebjs.connected && connectSocketForWwebjs(user.user.id);
  }, []);
 
  return (
    <>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        widht='100%'
        height='100%'
      >
        <Card
          elevation={4}
          sx={{
            display: "flex",
            borderRadius: '16px',
            width: wwebjs.connected ? {
              sm: '450px',
              xs: '80%'
            } : '80%',
            flexDirection: {
              sm: "column-reverse",
              md: "row",
              xs: "column-reverse",
            },
          }}
        >
          {wwebjs.connected ? (
            ""
          ) : (
            <Box width={'100%'} px={2}>
              <Typography
                variant="h5"
                textAlign="left"
                padding="0.5rem 0.5rem"
                marginTop="2rem"
              >
                Use WhatsApp on your computer
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem",
                }}
              >
                <ol className="cs-ol">
                  <li className="cs-li">
                    <Typography
                      variant="body1"
                    >
                      Open whatsApp on your phone
                    </Typography>
                  </li>
                  <li className="cs-li">
                    {" "}
                    <Typography variant="body1" lineHeight="5px">
                      Tab Menu
                      <IconButton>
                        <MenuIcon sx={{ fontSize: "1.2rem" }} />
                      </IconButton>
                      or
                      <IconButton>
                        <SettingsOutlinedIcon sx={{ fontSize: "1.2rem" }} />
                      </IconButton>{" "}
                      and select linked devices
                    </Typography>{" "}
                  </li>
                  <li className="cs-li">
                    {" "}
                    <Typography variant="body1">
                      Tab on link a device
                    </Typography>{" "}
                  </li>
                  <li className="cs-li">
                    {" "}
                    <Typography variant="body1">
                      Point your phone to the screen to capture the QR code
                    </Typography>
                  </li>
                </ol>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              padding: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "15rem",
              width: '100%',
            }}
          >
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {wwebjs.connected ? (
                <>
                  <Box>
                    <WhatsAppIcon
                      sx={{
                        fontSize: "7rem",
                        color: "green",
                        marginBottom: "1rem",
                      }}
                    ></WhatsAppIcon>
                  </Box>

                  <Typography variant="h5" textAlign='center'>Whatsapp is connected</Typography>
                  <Typography textAlign={'center'}>
                    If you disconnected manually
                    <Box
                      component="span"
                      sx={{ color: "green", cursor: "pointer" }}
                      onClick={() => {
                        connectSocketForWwebjs(user.user.id);
                        dispatch(resetWwebStatus());
                      }}
                    >
                      {` click here `}
                    </Box>
                    to reconnect
                  </Typography>
                </>
              ) : wwebjs.ready ? (
                <>
                  <CircularProgress />
                  <Typography p={1} textAlign="center">
                    Saving your session <br />
                    <Box component="span" fontSize="14px" color="gray">
                      It takes 3-4 mins to establish secure connection with
                      WhatsApp. Pls wait..
                    </Box>
                  </Typography>
                </>
              ) : wwebjs.qr ? (
                <>
                  <QRCode value={wwebjs.qr} />
                </>
              ) : wwebjs.loading ? (
                <>
                  <CircularProgress />
                  <Typography p={1}>Loading {wwebjs.loading}%</Typography>
                </>
              ) : wwebjs.authenticated ? (
                <Typography>Athunticated</Typography>
              ) : wwebjs.socketConnected  ? (
                <>
                  <CircularProgress />
                  <Typography p={1}>Wait Your request is processing</Typography>
                </>
              ) : (
                <Button
                  variant="contained"
                >
                  Connect Whatsapp
                </Button>
              )}
            </Stack>
          </Box>
        </Card>
      </Box>
    </>
  );
}
