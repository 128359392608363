import React from "react";
import { Chart, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
Chart.register(Tooltip, Legend, ArcElement);


const FeatureChart = ({ labelsNames, dataValues, type, colors }) => {
  const data = {
    labels: labelsNames,
    datasets: [
      {
        data: dataValues,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        display: true,
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
  };

  return (
    <div style={{ height: "50vh", width: "20rem" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default FeatureChart;
