//@ts-check
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container, alpha } from "@mui/material";
import {
  HEADER_HEIGHT,
  HELP_MSG,
  WEBSITE_NAME,
  freeTrialMsg,
} from "../../constants.js";
import { Link } from "react-router-dom";
import { customFetch } from "../../util/axios.js";
import authHeader from "../../util/authToken.js";

//<Container position={'relative'} zIndex={3}>
const mock = [
  {
    title: "Starter",
    subtitle: "Get started with our starter plan at no cost",
    price: { monthly: "Free", annual: "Free" },
    features: [
      "Free plugin",
      "Personalized messages",
      "Send images",
      "Send documents",
      "Random time-gap between messages",
      "100 contacts per month",
      "100 messages per month",
    ],
    isHighlighted: false,
    buttonText: "Start for Free",
    msg: freeTrialMsg,
  },
  {
    title: "Pro",
    subtitle: "Unlock the world of unlimited possibilities with our pro plan",
    price: { monthly: "Rs. 830", annual: "Rs. 8,300" },
    features: [
      "Free plugin",
      "Personalized messages",
      "Send images",
      "Send documents",
      "Random time-gap between messages",

      "Unlimited contacts",
      "Unlimited messages",
    ],
    isHighlighted: true,
    buttonText: "Get Pro",

    msg: HELP_MSG,
  },
];

function PricingGridTest() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [pricingOption, setPricingOption] = useState("monthly");

  const handleClick = (event, newPricingOption) => {
    if (!newPricingOption) {
      return;
    }
    setPricingOption(newPricingOption);
  };

  async function goToPaymentPage(planDetails){
    const {data} = await customFetch.post('/api/v1/payment_url', {
      planType: planDetails.planType,
      duration: planDetails.duration,
    }, authHeader());

    console.log(data);
    if(!data.url){
      return;
    }
    
    window.location.href = data.url;
  }

  const renderToggler = () => (
    <Box display={"flex"} justifyContent={"center"} marginBottom={4}>
      <ToggleButtonGroup
        value={pricingOption}
        exclusive
        onChange={handleClick}
        sx={{
          "& .MuiToggleButton-root": {
            border: (th) => `1px solid ${th.palette.primary.main}`,
            "& .MuiTypography-subtitle2": {
              color: (th) => th.palette.primary.main,
            },
            "&:hover": {
              backgroundColor: (th) => alpha(th.palette.primary.light, 0.1),
            },
            "&.Mui-selected": {
              backgroundColor: (th) => th.palette.primary.main,
              "& .MuiTypography-subtitle2": {
                color: (th) => th.palette.primary.contrastText,
              },
              "&:hover": {
                backgroundColor: (th) => th.palette.primary.main,
              },
            },
          },
        }}
      >
        <ToggleButton value="monthly" size={isMd ? "large" : "small"}>
          <Typography
            onDoubleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color: "text.primary",
            }}
          >
            Monthly
          </Typography>
        </ToggleButton>
        <ToggleButton value="annual" size={isMd ? "large" : "small"}>
          <Typography
            onDoubleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color: "text.primary",
            }}
          >
            Annual
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <Box
      paddingY={6}
      id="pricing"
      marginTop={"80px"}
      marginBottom={"80px"}
      style={{ scrollMarginTop: `${HEADER_HEIGHT}px` }}
    >
      <Box
        sx={{
          position: "relative",
          //backgroundColor: theme.palette.alternate.main,
          //backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.dark} 100%)`,
        }}
      >
        <Box>
          <Box marginBottom={4}>
            <Typography
              variant="h3"
              gutterBottom
              align={"center"}
              sx={{
                fontWeight: 900,
              }}
            >
              Flexible pricing options
            </Typography>
            <Typography
              variant={"body1"}
              align={"center"}
              color={"text.secondary"}
            >
              Save time sending WhatsApp messages with {WEBSITE_NAME}.
            </Typography>
          </Box>
          {renderToggler()}
        </Box>
      </Box>
      <Container>
        <Grid
          container
          spacing={4}
          display="flex"
          justifyContent="center"
          justifyItems="center"
        >
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                component={Card}
                height={1}
                display={"flex"}
                flexDirection={"column"}
                variant={"outlined"}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2}>
                    <Typography variant={"h4"} fontWeight={600} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color={"text.secondary"}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"baseline"}
                    marginBottom={2}
                    textAlign={"center"}
                  >
                    <Typography
                      variant={"h3"}
                      textAlign={"center"}
                      fontWeight={700}
                    >
                      {pricingOption === "annual"
                        ? item.price.annual
                        : item.price.monthly}
                    </Typography>
                    <Typography
                      variant={"subtitle1"}
                      color={"text.secondary"}
                      fontWeight={700}
                    >
                      {item.price.annual === "Free"
                        ? ""
                        : pricingOption === "annual"
                        ? "/yr"
                        : "/mo"}
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={"auto"}
                          padding={0}
                        >
                          <Box
                            component={ListItemAvatar}
                            minWidth={"auto !important"}
                            marginRight={2}
                          >
                            <Box
                              component={Avatar}
                              bgcolor={theme.palette.primary.main}
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: "flex-end", padding: 4 }}>
                  {item.buttonText === "Start for Free" ? (
                    <Link to="/auth" style={{ width: "100%" }}>
                      <Button
                        size={"large"}
                        sx={{ fontWeight: 700 }}
                        variant={
                          item.title === "Pro" ? "contained" : "outlined"
                        }
                        fullWidth
                        // href="/auth"
                      >
                        {item.buttonText}
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      size={"large"}
                      sx={{ fontWeight: 700 }}
                      variant={item.title === "Pro" ? "contained" : "outlined"}
                      fullWidth
                      // href={`https://api.whatsapp.com/send?phone=${PHONE}&text=${encodeURIComponent(
                      //   pricingOption === "monthly"
                      //     ? `Hi, I want to start monthly subscription of ${WEBSITE_NAME}`
                      //     : `Hi, I want to start yearly subscription of ${WEBSITE_NAME}`
                      // )}`}
                      // target="_blank"
                      // rel="noopener noreferrer"
                      onClick={() => goToPaymentPage({
                        planType: 'pro',
                        duration: pricingOption
                      })}
                    >
                      {item.buttonText}
                    </Button>
                  )}
                </CardActions>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default PricingGridTest;
