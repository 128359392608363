import { Box, Paper, Typography } from "@mui/material";
import { PageStyle } from "../../components/PageStyle";
import { Timer } from "@mui/icons-material";

export default function Feed(){
    return <PageStyle>
        <Box display='flex' width='100%' height='100%' justifyContent='center' alignItems='center'>
            <Paper 
                sx={{
                    padding: (theme) => theme.spacing(4),
                    textAlign: 'center',
                    maxWidth: 400,
                    borderRadius: '16px',
                }} 
                elevation={8}
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                <Timer  color="primary" sx={{
                        fontSize: 64,
                        marginBottom: (theme) => theme.spacing(2),
                    }} />
                <Typography variant="h4" component="h1" gutterBottom>
                    Coming Soon
                </Typography>
                <Typography variant="body1">
                    We are working on an exciting new feature. Stay tuned!
                </Typography>
                </Box>
            </Paper>
        </Box>
    </PageStyle>
}