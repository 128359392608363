import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCheck,
  faCalendarCheck,
  faEnvelopeCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
function WhyUse() {
  const data = [
    {
      title: 'Who to message?',
      subtitle: 'Reach the right people effortlessly.',
      useIcon: faUserCheck,
    },
    {
      title: 'When to message?',
      subtitle: 'Timely communication for maximum impact.',
      useIcon: faCalendarCheck,
    },
    {
      title: 'What to message?',
      subtitle: 'Tailored content for every occasion.',
      useIcon: faEnvelopeCircleCheck,
    },
  ];

  return (
    <Box
      id=""
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        flexDirection: 'column',
        justifyItems: 'center',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: 'primary.main',
          borderRadius: '16px',
          padding: '4px',
        }}
      >
        <Box marginBottom={3} marginTop={8}>
          <Typography variant="h3" color="white" align="center">
            Why use ChatEngage for WhatsApp Messaging?
          </Typography>
          <Typography
            variant="body1"
            color="white"
            align="center"
            marginTop={2}
          >
            Our AI Assistant: Effortlessly Manage the Who, What, and When of
            Messaging, Freeing Your Time
          </Typography>
        </Box>

        <Grid
          container
          spacing={4}
          marginTop={4}
          marginBottom={4}
          display={'flex'}
        >
          {data.map((item, i) => (
            <Grid
              item
              xs={12}
              md={4}
              key={i}
              display={'flex'}
              justifyContent={'center'}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent={'center'}
                alignItems="center"
                marginBottom={{ xs: 2, md: 4 }}
              >
                {/* <Box component="img" src={item.image} width="250px"></Box> */}
                <FontAwesomeIcon
                  icon={item.useIcon}
                  size="3x"
                  style={{ color: 'white' }}
                />

                <Box>
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    color={'white'}
                    sx={{ fontWeight: 700, marginTop: '20px' }}
                    align="center"
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color={'white'} align="center">
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default WhyUse;
