import React from 'react';

import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Stack,
} from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Menu } from '@mui/icons-material';
import logo from '../../../src/assets/img/CElogo.png';

const drawerWidth = 240;

const navButtons = (
  <Box display="flex" flexDirection="row">
    <Box
      sx={{ display: { xs: 'none', sm: 'none', md: 'flex', flexShrink: '1' } }}
    >
      <HashLink
        smooth
        to="/#home"
        style={{ textDecoration: 'none', flexShrink: '1' }}
      >
        <Button size="large" color="primary" sx={{ margin: '5px 20px' }}>
          Home
        </Button>
      </HashLink>

      <HashLink smooth to="/#campaigns" style={{ textDecoration: 'none' }}>
        <Button size="large" color="primary" sx={{ margin: '5px 20px' }}>
          Campaigns
        </Button>
      </HashLink>

      <HashLink smooth to="/#benefits" style={{ textDecoration: 'none' }}>
        <Button size="large" color="primary" sx={{ margin: '5px 20px' }}>
          Benefits
        </Button>
      </HashLink>

      <HashLink smooth to="/#contact" style={{ textDecoration: 'none' }}>
        <Button size="large" color="primary" sx={{ margin: '5px 20px' }}>
          Contact
        </Button>
      </HashLink>

      <HashLink smooth to="/#industry" style={{ textDecoration: 'none' }}>
        <Button size="large" color="primary" sx={{ margin: '5px 20px' }}>
          Industries
        </Button>
      </HashLink>

      <HashLink smooth to="/#faq" style={{ textDecoration: 'none' }}>
        <Button size="large" color="primary" sx={{ margin: '5px 20px' }}>
          FAQs
        </Button>
      </HashLink>

      <Button
        color="primary"
        variant="contained"
        size="large"
        sx={{ fontWeight: '600' }}
        component={Link}
        to="/auth"
      >
        Login
      </Button>
    </Box>
  </Box>
);

export default function DrawerAppBar() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  function toggleDrawer() {
    setOpenDrawer(!openDrawer);
  }

  return (
    <Box>
      <AppBar
        sx={{ margin: '0px', position: 'fixed', height: '60px' }}
        color=""
      >
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}
          >
            <HashLink smooth to="/#home" style={{ textDecoration: 'none' }}>
              <Stack
                direction="row"
                spacing={1}
                display={'flex'}
                justifyItems={'center'}
                alignItems={'center'}
              >
                <img src={logo} alt="logo big" height="60px" />
                <Typography
                  variant="h5"
                  color={'primary'}
                  align="center"
                  justifyContent={'center'}
                >
                  ChatEngage
                </Typography>
              </Stack>
            </HashLink>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'block', sm: 'block', md: 'none' },
            }}
          >
            <HashLink smooth to="/#home" style={{ textDecoration: 'none' }}>
              <Stack
                direction="row"
                spacing={1}
                display={'flex'}
                justifyItems={'center'}
                alignItems={'center'}
              >
                <img src={logo} alt="logo small" height="40px" />
                <Typography
                  variant="h6"
                  color={'primary'}
                  align="center"
                  justifyContent={'center'}
                >
                  ChatEngage
                </Typography>
              </Stack>
            </HashLink>
          </Box>
          {navButtons}
          <IconButton
            color="inherit"
            onClick={toggleDrawer}
            sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          anchor="left"
          open={openDrawer}
          onClose={toggleDrawer}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            display={'flex'}
            justifyItems={'center'}
            alignItems={'center'}
            alignSelf={'center'}
            minHeight={'60px'}
          >
            <img src={logo} alt="logo small" height="40px" />
            <Typography
              variant="h6"
              color={'primary'}
              align="center"
              justifyContent={'center'}
            >
              ChatEngage
            </Typography>
          </Stack>
          <Divider />
          <List sx={{ alignSelf: 'center' }}>
            <ListItem disablePadding>
              <ListItemButton href="/#home" onClick={toggleDrawer}>
                <ListItemText primary="Home" sx={{ textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton href="/#campaigns" onClick={toggleDrawer}>
                <ListItemText
                  primary="Campaigns"
                  sx={{ textAlign: 'center' }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton href="/#benefits" onClick={toggleDrawer}>
                <ListItemText primary="Benefits" sx={{ textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton href="/#contact" onClick={toggleDrawer}>
                <ListItemText primary="Contact" sx={{ textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton href="/#industry" onClick={toggleDrawer}>
                <ListItemText
                  primary="Industries"
                  sx={{ textAlign: 'center' }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton href="/#faq" onClick={toggleDrawer}>
                <ListItemText primary="FAQs" sx={{ textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <Button
                color="primary"
                variant="contained"
                size="large"
                sx={{ fontWeight: '600' }}
                component={Link}
                to="/auth"
                onClick={toggleDrawer}
              >
                Login
              </Button>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}

// export default DrawerAppBar;
