import React from "react";
import messagesSent from "../../../src/assets/img/dashboard_messagesSent.png";
import campaign from "../../../src/assets/img/dashboard_campaigns.png";
import totalCustomers from "../../../src/assets/img/dashboard_customers.png";
import totalMessage from "../../../src/assets/img/dashboard_messagesTotal.png";
import {
  Box,
  Card,
  LinearProgress,
  Stack,
  Typography,
  Grid,
  Skeleton,
} from "@mui/material";
import FeatureChart from "../components/Chart";
import { customFetch } from "../../util/axios";
import authHeader from "../../util/authToken";
import { useState } from "react";
import { useEffect } from "react";

export default function Dashboard() {
  const [totalMessages, setTotalMessages] = useState(10024);
  const [customerStats, setCustomerStats] = useState({});
  const [customerStatsLoading, setCustomerStatsLoading] = useState(true);
  const [campaignStats, setCampaignStats] = useState({});
  const [campaignStatsLoading, setCampaignStatsLoading] = useState(true);

  async function getStats() {
    const { data: customer_stats } = await customFetch.get(
      `api/v1/user/customer_stats`,
      authHeader()
    );
    const { data: campaign_stats } = await customFetch.get(
      `api/v1/user/campaign_stats`,
      authHeader()
    );

    // console.log(customer_stats, campaign_stats);
    setCustomerStats(customer_stats);
    setCampaignStats(campaign_stats);
    setCampaignStatsLoading(false);
    setCustomerStatsLoading(false);
  }

  useEffect(() => {
    getStats();
  }, []);
  useEffect(() => {
    setTotalMessages(campaignStats.totalMsgForCurrentMonth);
  }, [campaignStats]);

  const graphColors = ["#f59e0b", "#22c55e", "#3b82f6", "#ef4444"];
  const { graphData, graphLabel } = Object.keys(customerStats).reduce(
    (accumelator, statKey) => {
      if (statKey === "total") return accumelator;

      accumelator.graphData.push(customerStats[statKey]);
      accumelator.graphLabel.push(statKey);

      return accumelator;
    },
    {
      graphData: [],
      graphLabel: [],
    }
  );

  return (
    <>
    
        <Grid container justifyContent="space-evenly" rowGap={3}>
          <Grid item>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "165px",
                padding: "0.5rem 1rem",
                borderRadius: "16px",
                alignItems: "center",
                border: "1px solid whitesmoke",
                width: "15rem",
              }}
            >
              {customerStatsLoading ? (
                <Skeleton variant="rectangular" width={80} height={80} />
              ) : (
                <Box
                  component="img"
                  src={totalMessage}
                  width="80px"
                  margin="auto"
                />
              )}
              {customerStatsLoading ? (
                <Skeleton variant="text" width={100} height={40} />
              ) : (
                <Typography
                  variant="h4"
                  fontWeight="700"
                  marginBottom={1}
                  textAlign="center"
                >
                  {campaignStats.totalMessage}
                </Typography>
              )}
              {customerStatsLoading ? (
                // Render the Skeleton while loading
                <Skeleton variant="text" width={150} height={20} />
              ) : (
                <Typography variant="body2" color="gray">
                  Total Messages
                </Typography>
              )}
            </Card>
          </Grid>
          <Grid item>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "165px",
                padding: "0.5rem 1rem",
                borderRadius: "16px",
                alignItems: "center",

                border: "1px solid whitesmoke",
                width: "15rem",
              }}
            >
              {customerStatsLoading ? (
                <Skeleton variant="rectangular" width={80} height={80} />
              ) : (
                <Box
                  component="img"
                  src={messagesSent}
                  width="80px"
                  margin="auto"
                />
              )}
              {customerStatsLoading ? (
                <Skeleton variant="text" width={100} height={40} />
              ) : (
                <Typography
                  variant="h4"
                  fontWeight="700"
                  marginBottom={1}
                  textAlign="center"
                >
                  {campaignStats.totalMsgForCurrentMonth || '0'}
                </Typography>
              )}

              {customerStatsLoading ? (
                // Render the Skeleton while loading
                <Skeleton variant="text" width={150} height={20} />
              ) : (
                <Typography variant="body2" color="gray">
                  Messages Current Month
                </Typography>
              )}
            </Card>
          </Grid>
          <Grid item>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "165px",
                padding: "0.5rem 1rem",
                borderRadius: "16px",
                alignItems: "center",

                border: "1px solid whitesmoke",
                width: "15rem",
              }}
            >
              {customerStatsLoading ? (
                <Skeleton variant="rectangular" width={80} height={80} />
              ) : (
                <Box
                  component="img"
                  src={campaign}
                  width="80px"
                  margin="auto"
                />
              )}
              {customerStatsLoading ? (
                <Skeleton variant="text" width={100} height={40} />
              ) : (
                <Typography
                  variant="h4"
                  fontWeight="700"
                  marginBottom={1}
                  textAlign="center"
                >
                  {campaignStats.noOfActiveCampaign}
                </Typography>
              )}

              <Typography
                variant="h4"
                fontWeight="700"
                marginBottom={1}
                textAlign="center"
              ></Typography>
              {customerStatsLoading ? (
                // Render the Skeleton while loading
                <Skeleton variant="text" width={150} height={20} />
              ) : (
                <Typography variant="body2" color="gray">
                  Number of Campaigns
                </Typography>
              )}
            </Card>
          </Grid>
          <Grid item>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "165px",
                padding: "0.5rem 1rem",
                borderRadius: "16px",
                alignItems: "center",

                border: "1px solid whitesmoke",
                width: "15rem",
              }}
            >
              {customerStatsLoading ? (
                <Skeleton variant="rectangular" width={80} height={80} />
              ) : (
                <Box
                  component="img"
                  src={totalCustomers}
                  width="80px"
                  margin="auto"
                />
              )}
              {customerStatsLoading ? (
                <Skeleton variant="text" width={100} height={40} />
              ) : (
                <Typography
                  variant="h4"
                  fontWeight="700"
                  marginBottom={1}
                  textAlign="center"
                >
                  {customerStats.total}
                </Typography>
              )}

              {customerStatsLoading ? (
                // Render the Skeleton while loading
                <Skeleton variant="text" width={150} height={20} />
              ) : (
                <Typography variant="body2" color="gray">
                  Number of Customers
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>

        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          justifyContent="space-around"
          gap={5}
          width="90%"
          margin="auto"
          marginTop={5}
        >
          {/* <Card sx={{ width: { sm: "100%", md: "50%" }, padding: "1rem" }}>
            <Typography variant="h6" marginBottom={2}>
              {campaignStatsLoading ? (
                <Skeleton width={200} height={24} />
              ) : (
                "Messages Getting Sent This Month"
              )}

              
            </Typography>

            
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={100} height={16} />
                ) : (
                  "Birthday"
                )}
              </Typography>
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={20} height={16} />
                ) : (
                  "5"
                )}
              </Typography>
            </Stack>
            {campaignStatsLoading ? (
              <Skeleton width="100%" height={16} />
            ) : (
              <LinearProgress
                variant="determinate"
                value={10}
                sx={{ height: "10px", borderRadius: "20px", marginBottom: 2 }}
              />
            )}

            
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={100} height={16} />
                ) : (
                  "Anniversary"
                )}
              </Typography>
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={20} height={16} />
                ) : (
                  "4"
                )}
              </Typography>
            </Stack>
            {campaignStatsLoading ? (
              <Skeleton width="100%" height={16} />
            ) : (
              <LinearProgress
                variant="determinate"
                value={10}
                sx={{ height: "10px", borderRadius: "20px", marginBottom: 2 }}
              />
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={100} height={16} />
                ) : (
                  "Fathers Day"
                )}
              </Typography>
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={20} height={16} />
                ) : (
                  "1500"
                )}
              </Typography>
            </Stack>
            {campaignStatsLoading ? (
              <Skeleton width="100%" height={16} />
            ) : (
              <LinearProgress
                variant="determinate"
                value={50}
                sx={{ height: "10px", borderRadius: "20px", marginBottom: 2 }}
              />
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={100} height={16} />
                ) : (
                  "Yoga Day"
                )}
              </Typography>
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={20} height={16} />
                ) : (
                  "1500"
                )}
              </Typography>
            </Stack>
            {campaignStatsLoading ? (
              <Skeleton width="100%" height={16} />
            ) : (
              <LinearProgress
                variant="determinate"
                value={50}
                sx={{ height: "10px", borderRadius: "20px", marginBottom: 2 }}
              />
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={100} height={16} />
                ) : (
                  "Bakra eid"
                )}
              </Typography>
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={20} height={16} />
                ) : (
                  "4"
                )}
              </Typography>
            </Stack>
            {campaignStatsLoading ? (
              <Skeleton width="100%" height={16} />
            ) : (
              <LinearProgress
                variant="determinate"
                value={50}
                sx={{ height: "10px", borderRadius: "20px", marginBottom: 2 }}
              />
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={100} height={16} />
                ) : (
                  "Weekly Market Updates"
                )}
              </Typography>
              <Typography>
                {campaignStatsLoading ? (
                  <Skeleton width={20} height={16} />
                ) : (
                  "1500"
                )}
              </Typography>
            </Stack>
            {campaignStatsLoading ? (
              <Skeleton width="100%" height={16} />
            ) : (
              <LinearProgress
                variant="determinate"
                value={80}
                sx={{ height: "10px", borderRadius: "20px", marginBottom: 2 }}
              />
            )}

            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography fontWeight="bold">
                {campaignStatsLoading ? (
                  <Skeleton width={180} height={16} />
                ) : (
                  "Total messages sent"
                )}
              </Typography>
              <Typography fontWeight="bold">
                {campaignStatsLoading ? (
                  <Skeleton width={40} height={16} />
                ) : (
                  "2500"
                )}
              </Typography>
            </Stack>
          </Card> */}
          <Box
            width={{ sm: "100%", md: "50%" }}
            display="flex"
            alignItems="center"
            justifyContent="centerd"
            flexDirection="column"
          >
            <Card sx={{ padding: "1.5rem" }}>
              {campaignStatsLoading ? (
                <Skeleton variant="text" width={150} height={20} />
              ) : (
                <Typography variant="h6" marginBottom={2}>
                  Customer Stats
                </Typography>
              )}
              {campaignStatsLoading ? (
                <Skeleton variant="circular" height="20rem" width="20rem" />
              ) : (
                <FeatureChart
                  colors={graphColors}
                  labelsNames={graphLabel}
                  dataValues={graphData}
                />
              )}
            </Card>
          </Box>
        </Stack>
      
    </>
  );
}

