

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { customFetch } from "../../util/axios";
import SaveIcon from "@mui/icons-material/Save";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import authHeader from "../../util/authToken";
import CloseIcon from "@mui/icons-material/Close";

export default function UserProfilePage() {
  const initialState = {
    name: "",
    lastName: "",
    email: "",
    password: "",
    gstn: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
    },
    isMember: true,
    company: "",
  };
  const { user } = useSelector((store) => store.user);
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(true);
  const [values, setValues] = useState(initialState);

  const getUser = async () => {
    let { data } = await customFetch.get(
      `api/v1/user/${user?.user.id}`,
      authHeader()
    );

    setValues({ ...initialState, ...data });
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleSubmit = async () => {
    try {
      const { data } = await customFetch.patch(
        `api/v1/user/${user.user.id}`,
        values,
        authHeader()
      );
      setValues(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const name = String(e.target.name);
    const value = e.target.value;

    if (name.startsWith("address")) {
      const addressPart = name.split("_")[1];
      values.address[addressPart] = value;
      setValues({ ...values });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  if (isLoading) {
    return (
    
        <Box
          sx={{
            height: "60vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      
    );
  }

  return (
  
      <Box>
        <Card
          sx={{
            padding: "2rem 2rem",
            maxWidth: "40rem",
            margin: "2rem auto",
            borderRadius: "16px",
          }}
          elevation={5}
        >
          <form>
            <Grid
              container
              columnSpacing={2}
              rowSpacing={2}
              alignItems="center"
              minHeight="70vh"
            >
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  className="cs-input-user-profile-page"
                  id="standard-basic"
                  label="First Name"
                  name="name"
                  size="small"
                  fullWidth
                  variant="outlined"
                  disabled={isEditable}
                  value={values.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  id="standard-basic"
                  name="lastName"
                  label="Last Name"
                  size="small"
                  variant="outlined"
                  value={values.lastName}
                  disabled={isEditable}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  id="standard-basic"
                  size="small"
                  label="Phone Number"
                  variant="outlined"
                  name="phone"
                  disabled={isEditable}
                  value={values.phone}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  id="standard-basic"
                  label="Email"
                  size="small"
                  variant="outlined"
                  name="email"
                  value={values.email}
                  disabled={isEditable}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Company Name"
                  multiline
                  size="small"
                  rows={2}
                  fullWidth
                  name="company"
                  value={values.company}
                  disabled={isEditable}
                  onChange={handleChange}
                  defaultValue={`From Wealth Serve`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  label="GSTN"
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="gstn"
                  value={values.gstn}
                  disabled={isEditable}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  label="Address"
                  size="small"
                  fullWidth
                  variant="outlined"
                  name="address_addressLine1"
                  value={values.address.addressLine1}
                  disabled={isEditable}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="City"
                  size="small"
                  fullWidth
                  variant="outlined"
                  name="address_city"
                  value={values.address.city}
                  disabled={isEditable}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  size="small"
                  label="State"
                  fullWidth
                  variant="outlined"
                  name="address_state"
                  value={values.address.state}
                  disabled={isEditable}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Pincode"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="address_pincode"
                  value={values.address.pinconde}
                  disabled={isEditable}
                  onChange={handleChange}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{ marginTop: "0.5rem" }}
                // sx={{ }}
                paddingLeft={3.7}
              >
                {!isEditable ? (
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Button
                      variant="contained"
                      onClick={() => setIsEditable(!isEditable)}
                      startIcon={<CloseIcon color="white" />}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setIsEditable(!isEditable);
                        handleSubmit();
                      }}
                      // sx={{ marginLeft: "auto" }}
                      sx={{ display: "flex" }}
                      startIcon={
                        !isBtnLoading ? (
                          <CircularProgress />
                        ) : (
                          <SaveIcon color="white" />
                        )
                      }
                    >
                      Save
                    </Button>
                  </Box>
                ) : (
                  <Button
                    onClick={() => setIsEditable(!isEditable)}
                    variant="contained"
                    startIcon={<ModeEditIcon color="white" />}
                    sx={{ display: "flex", color: "white" }}
                    // color="secondary.main"
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
    
  );
}
