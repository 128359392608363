import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    connected: false,
    ready: false,
    authenticated: false,
    qr: null,
    loading: null,
    socketConnected: false,
    firstAttempt: true,
}

const wwebjsSlice = createSlice({
    name: 'wwebjs',
    initialState,
    reducers: {
        updateWwebjsStatus: (state, { payload }) => {
            const {connected, ready, authenticated, qr, loading, socketConnected} = payload;
            
            if(connected !== undefined){
                state.connected = connected;
            }
            if(ready !== undefined){
                state.ready = ready;
            }           
            if(authenticated !== undefined){
                state.authenticated = authenticated;
            }           
            if(qr !== undefined){
                state.qr = qr;
            }           
            if(loading !== undefined){
                state.loading = String(loading);
            }           
            if(socketConnected !== undefined){
                state.socketConnected = socketConnected;
            }           
        },
        resetWwebStatus: (state) => {
            state.connected = false;
            state.ready = false;
            state.socketConnected = false;
            state.authenticated = false;
            state.loading = null;
            state.qr = null;
            state.firstAttempt = false;
        }
    }
});

export const { updateWwebjsStatus, resetWwebStatus } = wwebjsSlice.actions;
export default wwebjsSlice.reducer;