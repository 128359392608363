// export const BASE_URL = 'http://localhost:3001';
export const BASE_URL = 'https://wadev.herokuapp.com';

export const HEADER_HEIGHT = 61;
export const HELP_MSG = "I need help";
export const WEBSITE_NAME = "sheets-t0-whatsapp";

export const genders = ['Male', 'Female', 'Other'];
export const professions = [
  'Engineer',
  'Doctor',
  'Lawyer',
  'Teacher',
  'Accountant',
  'Police',
  'Business',
  'Other',
];

export const displayHeaderLabel = {
  //   clientCode: "Client Code",
  fullName: "Full Name",
  phone: "Phone",
  email: "Email",
  gender: "Gender",
  profession: "Profession",
  dob: "DOB",
  anniversary: "Anniversary",
  firstName: "First Name",
  lastName: "Last Name",
};

export const phone = '917039604776';
export const freeTrialMsg =
  'Hello, I like ChatEngage and I want to start a free trial!';
export const helpMsg = 'Hello, I need help with ChatEngage account';
