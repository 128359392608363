import React, { useEffect } from "react";
import { PageStyle } from "../../components/PageStyle";
import {
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Delete } from "@mui/icons-material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { customFetch } from "../../util/axios";
import authHeader from "../../util/authToken";
import { toast } from "react-toastify";
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from "../components/customStyled/customAccordion";
import RectangularAvatar from "../components/customStyled/RectangularAvatar";
import { ConfirmationDialog } from "../components/confirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomer } from "../../slice/customerSlice";
import { cronToDate } from "../../util/dateFunction";

function DisplayCampaigns({ campaignsStatus }) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [reqInProgress, setReqInProgress] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState({});
  const [scheduledCampaigns, setScheduledCampaigns] = useState([]);
  const [presetCampaigns, setPresetCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [personalCampaigns, setPersonalCampaigns] = useState([]);
  const [personalCampaignsStructure, setPersonalCampaignsStructure] = useState(
    {}
  );
  const [showCustomer, setShowCustomer] = useState(false);
  const [campaignSentTo, setCampaignSentTo] = useState([]);
  const [completedPresetCampaigns, setCompletedPresetCampaigns] = useState([]);

  const { allCustomer } = useSelector((store) => store.customer);
  const dispatch = useDispatch();

  function isDateLiesBetweenNext30Days(date) {
    const today = new Date();
    const campaignDate = new Date(date);
    if (campaignDate < today) {
      return false;
    }
    today.setDate(today.getDate() + 30);
    if (campaignDate > today) {
      return false;
    }

    return true;
  }

  function isDateSmallerThenNext30Days(date) {
    const today = new Date();
    const campaignDate = new Date(date);
    today.setDate(today.getDate() + 30);

    return campaignDate < today;
  }

  useEffect(() => {
    if (campaignsStatus !== "completed") {
      return;
    }

    customFetch
      .get("/api/v1/preset_camp/completed", authHeader())
      .then(({ data }) => {
        setCompletedPresetCampaigns(data);
        console.log(data);
      })
      .catch((err) => {
        console.error("unable to load completed preset campaigns", err);
      });
  }, []);

  useEffect(() => {
    if (campaignsStatus === "completed") {
      return;
    }
    if (!Object.keys(personalCampaignsStructure).length) {
      return;
    }

    const birthdayCampaign = personalCampaignsStructure.birthday;
    const anniversaryCampaign = personalCampaignsStructure.anniversary;

    const sortedCustomerWithDate = allCustomer.reduce(
      (accumelator, customer) => {
        const dob = new Date(customer.dob);
        const birthDate = new Date();
        birthDate.setDate(dob.getDate());
        birthDate.setMonth(dob.getMonth());

        if (customer.dob && isDateSmallerThenNext30Days(birthDate)) {
          if (!accumelator.birthday[dateToLocalDateString(birthDate)]) {
            accumelator.birthday[dateToLocalDateString(birthDate)] = [];
          }
          accumelator.birthday[dateToLocalDateString(birthDate)].push(customer);
        }

        const anniversary = new Date(customer.anniversary);
        const anniversaryDate = new Date();
        anniversaryDate.setDate(anniversary.getDate());
        anniversaryDate.setMonth(anniversary.getMonth());

        if (
          customer.anniversary &&
          isDateSmallerThenNext30Days(anniversaryDate)
        ) {
          if (
            !accumelator.anniversary[dateToLocalDateString(anniversaryDate)]
          ) {
            accumelator.anniversary[dateToLocalDateString(anniversaryDate)] =
              [];
          }
          accumelator.anniversary[dateToLocalDateString(anniversaryDate)].push(
            customer
          );
        }

        return accumelator;
      },
      {
        birthday: {},
        anniversary: {},
      }
    );

    const _personalCampigns = [];
    for (const key in sortedCustomerWithDate) {
      if (Object.hasOwnProperty.call(sortedCustomerWithDate, key)) {
        if (key === "birthday") {
          for (const date in sortedCustomerWithDate[key]) {
            if (Object.hasOwnProperty.call(sortedCustomerWithDate[key], date)) {
              const customers = sortedCustomerWithDate[key][date];

              const birthdayDate = new Date(customers[0].dob);
              const campaignDate = new Date(birthdayCampaign.scheduledAt);
              campaignDate.setMonth(birthdayDate.getMonth());
              campaignDate.setDate(birthdayDate.getDate());

              let status = "completed";
              if (isDateLiesBetweenNext30Days(campaignDate)) {
                status = "queued";
              }

              if (status !== campaignsStatus) {
                continue;
              }

              _personalCampigns.push({
                ...birthdayCampaign,
                scheduledAt: campaignDate,
                status: status,
                customersToSend: customers,
              });
            }
          }
        } else {
          for (const date in sortedCustomerWithDate[key]) {
            if (Object.hasOwnProperty.call(sortedCustomerWithDate[key], date)) {
              const customers = sortedCustomerWithDate[key][date];

              const anniversaryDate = new Date(customers[0].anniversary);
              const campaignDate = new Date(anniversaryCampaign.scheduledAt);
              campaignDate.setMonth(anniversaryDate.getMonth());
              campaignDate.setDate(anniversaryDate.getDate());

              let status = "completed";
              if (isDateLiesBetweenNext30Days(campaignDate)) {
                status = "queued";
              }

              if (status !== campaignsStatus) {
                continue;
              }

              _personalCampigns.push({
                ...anniversaryCampaign,
                status: status,
                scheduledAt: campaignDate,
                customersToSend: customers,
              });
            }
          }
        }
      }
    }

    setPersonalCampaigns(_personalCampigns);
  }, [allCustomer, personalCampaignsStructure]);

  useEffect(() => {
    dispatch(getAllCustomer({}));

    customFetch
      .get("/api/v1/scheduled_camp", authHeader())
      .then((res) => {
        setScheduledCampaigns(
          res.data.filter((campaigns) => campaigns.status === campaignsStatus)
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error("unable to fatch scheduled campaign", err);
      });

    // if(campaignsStatus === 'completed') { return }

    customFetch
      .get("/api/v1/preset_camp", authHeader())
      .then((resForCmpgn) => {
        customFetch
          .get("/api/v1/preset_camp/status", authHeader())
          .then((resForStatus) => {
            //seting campaing status before campaign to avoid
            //error: a component is changing uncontrolled to controlled

            const _presetCampaigns = resForCmpgn.data.reduce(
              (accumelator, campaign) => {
                if (!resForStatus.data.presetCampaignsStatus[campaign._id]) {
                  return accumelator;
                }

                const campaignDate = cronToDate(campaign.occurence);
                if (campaign.category.toLowerCase() === "personal") {
                  {
                    if (campaign.name.toLowerCase() === "anniversary") {
                      personalCampaignsStructure.anniversary = {
                        ...campaign,
                        scheduledAt: campaignDate,
                      };
                    } else {
                      personalCampaignsStructure.birthday = {
                        ...campaign,
                        scheduledAt: campaignDate,
                      };
                    }
                    setPersonalCampaignsStructure({
                      ...personalCampaignsStructure,
                    });
                  }
                }

                let status = "completed";
                if (!isDateSmallerThenNext30Days(campaignDate)) {
                  return accumelator;
                }
                if (isDateLiesBetweenNext30Days(campaignDate)) {
                  status = "queued";
                }

                if (status !== campaignsStatus) {
                  return accumelator;
                }

                if (campaign.category.toLowerCase() !== "personal") {
                  accumelator.push({
                    ...campaign,
                    status: status,
                    scheduledAt: campaignDate,
                  });
                }
                return accumelator;
              },
              []
            );

            setPresetCampaigns(_presetCampaigns);

            setLoading(false);
          })
          .catch((err) => {
            console.error("unable to fetch preset campaign status", err);
          });
      })
      .catch((err) => {
        console.error("unable to fatch preset campaign", err);
      });
  }, []);

  //for opening and closeing delete confomation dialog
  function handleDeleteDialogOpen(campaign, e) {
    if (e) {
      e.stopPropagation();
    }
    setIsDeleteDialogOpen(true);
    setSelectedCampaign(campaign);
  }
  function handleDeleteDialogClose() {
    setIsDeleteDialogOpen(false);
  }

  //for deleting campaign
  async function deleteSelectedCampaign() {
    setReqInProgress(true);
    console.warn("Deleting campaign", selectedCampaign);
    const campaignId = selectedCampaign._id;
    handleDeleteDialogClose();
    try {
      await customFetch.delete(
        `/api/v1/scheduled_camp/${campaignId}`,
        authHeader()
      );
      setScheduledCampaigns(
        scheduledCampaigns.filter((campaign, i) => campaign._id !== campaignId)
      );
      toast.success("Campaign deleted.", {
        position: "bottom-left",
      });
    } catch (err) {
      toast.error("Failed to delete campaign.", {
        position: "bottom-left",
      });
      console.error("Unable to create campaign", err);
      setReqInProgress(false);
    } finally {
      setSelectedCampaign({});
    }
  }

  //hadleing accordion exapasions
  function handleAccordionChange(id) {
    return (event, isExpanded) => {
      setExpandedAccordion(isExpanded ? id : "");
    };
  }

  function combineAndSortCampaigns() {
    const presetAndScheduledCampaign =
      campaignsStatus !== "completed"
        ? [...presetCampaigns, ...scheduledCampaigns, ...personalCampaigns]
        : completedPresetCampaigns;
    return presetAndScheduledCampaign.sort((a, b) => {
      const accendingSort = new Date(a.scheduledAt) - new Date(b.scheduledAt);
      if (campaignsStatus === "completed") {
        const decendingSort = new Date(b.createdAt) - new Date(a.createdAt);
        return decendingSort;
      }

      return accendingSort;
    });
  }

  function extractNames(customerList) {
    const customerNames = customerList.reduce((accumelator, customer) => {
      accumelator.push(customer.firstName + " " + customer.lastName);
      return accumelator;
    }, []);
    return customerNames;
  }

  function dateToLocalDateString(inputDate) {
    return new Date(inputDate).toLocaleDateString("en-IN", {
      timeZone: "Asia/Kolkata",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  }
  function dateToLocalTimeString(inputDate) {
    return new Date(inputDate).toLocaleTimeString("en-IN", {
      hour: "2-digit",
      hour12: true,
      minute: "2-digit",
    });
  }

  return (
    <>
      {/* <Typography variant="h2">Campaign</Typography> */}
      <Box
        sx={{
          margin: {
            md: "3rem 5rem",
            xs: "3rem 0srem",
          },
          position: "relative",
        }}
      >
        {loading ? (
          // Render skeleton loading placeholder
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                key={index}
                height={114}
                width="100%"
                sx={{
                  position: "relative",
                  top: "-20px",
                  borderRadius: "16px",
                }}
              />
            ))}
          </>
        ) : (
          <>
            {combineAndSortCampaigns().map((campaign, i) => (
              <CustomAccordion
                expanded={expandedAccordion === i}
                onChange={handleAccordionChange(i)}
                width="100%"
                key={i}
              >
                <CustomAccordionSummary
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      width: "calc(100% - 32px)",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    overflow="hidden"
                    flexDirection={{
                      sm: "row",
                      xs: "column",
                    }}
                  >
                    <Box
                      gap={2}
                      display="flex"
                      alignItems="center"
                      width="100%"
                      overflow="hidden"
                      flexDirection={{
                        md: "row",
                        xs: "column",
                      }}
                      textAlign={{
                        md: "initial",
                        xs: "center",
                      }}
                    >
                      <RectangularAvatar
                        sx={{
                          backgroundColor:
                            campaignsStatus === "completed"
                              ? "rgba(46, 125, 50, 0.1)"
                              : "rgba(237, 108, 2, 0.1)",
                        }}
                      >
                        <Stack
                          sx={{
                            color: (theme) => {
                              return campaignsStatus === "completed"
                                ? theme.palette.success.main
                                : theme.palette.warning.main;
                            },
                            alignItems: "center",
                            fontWeight: "500",
                          }}
                        >
                          <Box fontSize={16}>
                            {new Date(
                              campaign.scheduledAt || campaign.createdAt
                            ).getDate()}
                          </Box>
                          <Box>
                            {new Date(
                              campaign.scheduledAt || campaign.createdAt
                            )
                              .toLocaleDateString("en-IN", { month: "short" })
                              .toUpperCase()}
                          </Box>
                        </Stack>
                      </RectangularAvatar>
                      <Stack overflow="hidden" width={"100%"}>
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          sx={{
                            transform: "200ms ease",
                          }}
                        >
                          {campaign.name ? campaign.name : "Scheduled Campaign"}
                        </Typography>
                        <Typography
                          display="flex"
                          component="div"
                          overflow={"hidden"}
                          flexWrap={{
                            sm: "nowrap",
                            xs: "wrap",
                          }}
                          gap={"5px"}
                          justifyContent={{
                            md: "initial",
                            xs: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="gray"
                            sx={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Msg Time :{" "}
                            {dateToLocalTimeString(
                              campaign.scheduledAt || campaign.createdAt
                            ) + " "}
                          </Typography>
                          {campaign.customersToSend &&
                          String(campaign.category).toLocaleLowerCase() ===
                            "personal" ? (
                            <Typography
                              variant="body2"
                              color="gray"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                ":hover": {
                                  fontWeight: 600,
                                },
                              }}
                              onClick={(e) => {
                                setShowCustomer(true);
                                setCampaignSentTo(campaign.customersToSend);
                                e.stopPropagation();
                              }}
                            >
                              {"| To : " +
                                extractNames(campaign.customersToSend).join(
                                  ", "
                                )}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              color="gray"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              | To : All Customers
                            </Typography>
                          )}
                        </Typography>
                      </Stack>
                    </Box>
                    {!campaign.occurence && campaignsStatus !== "completed" && (
                      <Box
                        margin="0 1rem"
                        width={(theme) => theme.spacing(5)}
                        height={(theme) => theme.spacing(5)}
                      >
                        <IconButton
                          onClick={(e) => handleDeleteDialogOpen(campaign, e)}
                          disabled={
                            selectedCampaign._id == campaign._id &&
                            reqInProgress
                          }
                          size="medium"
                          color="warning"
                        >
                          {selectedCampaign._id == campaign._id &&
                          reqInProgress ? (
                            <CircularProgress
                              color="inherit"
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                              }}
                            />
                          ) : (
                            <Delete color="error" />
                          )}
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <Box
                    paddingX={2}
                    paddingY={1}
                    whiteSpace={"pre-wrap"}
                    width={"100%"}
                  >
                    {campaign.image && (
                      <Box
                        component="img"
                        src={campaign.image}
                        max-height={300}
                        width={"100%"}
                      />
                    )}
                    <Box>{campaign.message}</Box>
                  </Box>
                </CustomAccordionDetails>
              </CustomAccordion>
            ))}
          </>
        )}
      </Box>

      {/* Dialog for confirmation of delete */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirmation={deleteSelectedCampaign}
        title={"Confirm Delete"}
        content={selectedCampaign.message}
      />

      {/*Dialog to Show Customer to whom message will sent */}
      <ConfirmationDialog
        open={showCustomer}
        onClose={() => setShowCustomer(false)}
        onConfirmation={() => setShowCustomer(false)}
        title={"Campaign will sent to"}
        content={
          <Box whiteSpace={"pre-wrap"}>
            {extractNames(campaignSentTo).join("\n")}
          </Box>
        }
        hideCancelButton
        confirmButtonText="okay"
      />
    </>
  );
}

//code for implemanting tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ScheduledCampaignPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Queued" {...a11yProps(0)} />
          <Tab label="Completed" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DisplayCampaigns campaignsStatus="queued" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DisplayCampaigns campaignsStatus="completed" />
      </TabPanel>
    </Box>
  );
}
