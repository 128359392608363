//@ts-check

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  Grid,
  Typography,
  Button,
  Autocomplete,
  Select,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomer,
  setSelectedCustomer,
  updateCustomer,
} from "../../slice/customerSlice";
import { toast } from "react-toastify";
import { customFetch } from "../../util/axios";
import authHeader from "../../util/authToken";
import IOSSwitch from "../components/customStyled/IOSSwitch";
import { genders, professions } from "../../constants";
import { InternalPage } from "../components/InternalPage";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { ConfirmationDialog } from "../components/confirmationDialog";
import { isObjectSame } from "../../util/objectFuction";

const initialCampaignStatus = {
  personal: true,
  festival: true,
  specialDays: true,
  stockmarket: true,
  marketing: true,
};

/**
 *
 * @param {{
 *  edit?: boolean
 * }} param0
 * @returns
 */
const EditCreatePage = ({ edit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCustomer, customHeaders } = useSelector(
    (store) => store.customer
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [firstName, setFirstName] = useState(selectedCustomer?.firstName || "");
  const [lastName, setLastName] = useState(selectedCustomer?.lastName || "");
  const [countryCode, setCountryCode] = useState(
    selectedCustomer?.countryCode || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(selectedCustomer?.phone || "");
  const [email, setEmail] = useState(selectedCustomer?.email || "");
  const [gender, setGender] = useState(selectedCustomer?.gender || null);
  const [profession, setProfession] = useState(
    selectedCustomer?.profession || null
  );
  const [isExist, setIsExist] = useState(false);
  const [isCustomerEnable, setIsCustomerEnable] = useState(
    selectedCustomer?.isEnabled !== undefined
      ? selectedCustomer.isEnabled
      : true
  );
  const [dateOfBirth, setDateOfBirth] = useState(
    selectedCustomer?.dob ? dayjs(selectedCustomer?.dob) : null
  );
  const [anniversary, setAnniversary] = useState(
    selectedCustomer?.anniversary ? dayjs(selectedCustomer?.anniversary) : null
  );
  const [categories, setCategories] = useState(
    selectedCustomer?.campaignCategoriesStatus
      ? selectedCustomer?.campaignCategoriesStatus
      : initialCampaignStatus
  );
  const [customFieldData, setCustomFieldData] = useState(
    selectedCustomer?.customFieldData || {}
  );

  const isAnythingChanged = () => {
    if (edit) {
      console.log(dayjs(selectedCustomer?.dob).toString());
      console.log(dateOfBirth?.toString());
      console.log(dayjs(selectedCustomer?.anniversary).toString());
      console.log(dayjs(null).toString());
      console.log(anniversary?.toString());
      switch (true) {
        case selectedCustomer?.firstName !== firstName:
          return true;
        case selectedCustomer?.lastName !== lastName:
          return true;
        case selectedCustomer?.countryCode !== countryCode:
          return true;
        case selectedCustomer?.phone !== phoneNumber:
          return true;
        case selectedCustomer?.email !== email:
          return true;
        case (selectedCustomer?.gender || null) !== gender:
          return true;
        case (selectedCustomer?.profession || null) !== profession:
          return true;
        case ![selectedCustomer?.isEnabled, true].includes(isCustomerEnable):
          return true;
        case ![dayjs(selectedCustomer?.dob).toString(), undefined].includes(
          dateOfBirth?.toString()
        ):
          return true;
        case ![
          dayjs(selectedCustomer?.anniversary).toString(),
          undefined,
        ].includes(anniversary?.toString()):
          return true;
        case !isObjectSame(selectedCustomer?.campaignCategoriesStatus, categories) : return true;
        default:
          return false;
      }
    }
    switch (true) {
      case "" !== firstName:
        return true;
      case "" !== lastName:
        return true;
      case "" !== countryCode:
        return true;
      case "" !== phoneNumber:
        return true;
      case "" !== email:
        return true;
      case null !== gender:
        return true;
      case null !== profession:
        return true;
      case true !== isCustomerEnable:
        return true;
      case null !== dateOfBirth:
        return true;
      case null !== anniversary:
        return true;
      case !isObjectSame(initialCampaignStatus, categories) : return true;
      default:
        return false;
    }
  };
  const close = () => {
    resetState();
    navigate("../");
  };

  function resetState() {
    dispatch(setSelectedCustomer(null));
    setFirstName("");
    setLastName("");
    setCountryCode("");
    setPhoneNumber("");
    setGender(null);
    setProfession(null);
    setDateOfBirth(null);
    setAnniversary(null);
    setIsCustomerEnable(true);
    setCategories(initialCampaignStatus);
  }

  const handleSave = () => {
    // Perform save operation here
    const customerData = {
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      phone: phoneNumber,
      email: email,
      countryCode: countryCode,
      profession: profession,
      anniversary: anniversary,
      dob: dateOfBirth,
      isEnabled: isCustomerEnable,
      campaignCategoriesStatus: categories,
      customFieldData: customFieldData,
    };

    if (!edit) {
      if (!firstName) return toast.error("please provide name");
      if (!phoneNumber) return toast.error("please provide phone nummber");
      if (!countryCode) return toast.error("please provide country code");
      if (phoneNumber.length !== 10)
        return toast.error("Pnone number must contain 10 digit");
    }

    if (edit) {
      //updating
      customerData._id = selectedCustomer._id;
      dispatch(updateCustomer(customerData));
    } else {
      //inserting
      dispatch(createCustomer(customerData));
    }
    close();
  };

  const handleCampaignStatusChange = useCallback(
    (event) => {
      setCategories({
        ...categories,
        [event.target.name]: event.target.checked,
      });
    },
    [categories]
  );

  const handleClose = () => {
    //if there is any change
    if (!isAnythingChanged()) {
      close();
      return;
    }
    setIsDialogOpen(true);
  };

  const checkIfExisit = useCallback(async () => {
    const resp = await customFetch.post(
      "/api/v1/customer/check-number",
      { phone: phoneNumber.trim() },
      authHeader()
    );

    if (resp.data.exist) {
      setIsExist(true);
    } else {
      setIsExist(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (!selectedCustomer && phoneNumber.length > 9) {
      checkIfExisit();
    }
  }, [phoneNumber, selectedCustomer, checkIfExisit]);
  return (
    <InternalPage
      heading={edit ? "Edit Customer Details" : "Add New Customer"}
      onClose={handleClose}
    >
      <Grid container maxWidth={"800px"} mt={3}>
        <Grid item xs={12}>
          <Typography fontWeight={600}>Common Fields</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            variant="body2"
            color={grey[500]}
            fontWeight={600}
            width={"200px"}
            flexShrink={0}
          >
            Chat Engage Fields
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="body2" color={grey[500]} fontWeight={600}>
            Values
          </Typography>
        </Grid>

        <DataInputRow
          lable="Client Name"
          value={firstName}
          placeholder="First Name"
          onChange={(value) => setFirstName(value)}
          required
          otherInput={{
            value: lastName,
            placeholder: "Last Name",
            onChange: (value) => setLastName(value),
          }}
        />

        {selectedCustomer && (
          <DataInputRow
            lable="Name in uploaded File"
            value={selectedCustomer?.rawName ? selectedCustomer.rawName : ""}
            onChange={(e) => {}}
            disabled
          />
        )}
        <DataInputRow
          lable="Country Code"
          value={countryCode}
          onChange={(value) =>
            setCountryCode(String(value).replace(/\D/g, "").substring(0, 5))
          }
          OtherProps={{
            InputProps: {
              startAdornment: countryCode ? "+" : "",
            },
          }}
          required
        />
        <DataInputRow
          lable="Phone Number"
          value={phoneNumber}
          onChange={(value) => {
            const phone = String(value).replace(/\D/g, "");
            if (phone.length > 10) return;
            setPhoneNumber(phone);
          }}
          required
          OtherProps={{
            helperText: isExist && "Number already exsit",
            error: isExist,
          }}
        />
        {selectedCustomer && (
          <DataInputRow
            lable="Phone in uploaded file"
            value={selectedCustomer?.rawPhone ? selectedCustomer.rawPhone : ""}
            onChange={(e) => {}}
            disabled
          />
        )}
        <DataInputRow
          lable="Email"
          value={email}
          onChange={(value) => setEmail(value)}
        />
        <DataSelectionRow
          lable="Gender"
          value={gender}
          onChange={(value) => setGender(value)}
          options={genders}
        />
        <DataSelectionRow
          lable="Profession"
          value={profession}
          onChange={(value) => setProfession(value)}
          options={professions}
        />
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography fontWeight={500} variant="body2">
            Date of Birth
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "200px", my: "8px" }}
              slotProps={{ textField: { size: "small" } }}
              value={dateOfBirth}
              format="DD/MM/YYYY"
              onChange={(e) => setDateOfBirth(e)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography fontWeight={500} variant="body2">
            Anniversary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "200px", my: "8px" }}
              slotProps={{ textField: { size: "small" } }}
              value={anniversary}
              format="DD/MM/YYYY"
              onChange={(e) => setAnniversary(e)}
            />
          </LocalizationProvider>
        </Grid>
        {customHeaders.filter(header => header.isCustom).map((header, i) => {
          return (
            <DataInputRow
              key={i}
              lable={header.name}
              value={customFieldData[header.name]}
              onChange={(value) => {
                setCustomFieldData({ ...customFieldData, [header.name]: value });
              }}
            />
          );
        })}

        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography fontWeight={500} variant="body2">
            Customer Status
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} my={"8px"}>
          <Box display="flex" flexDirection="row" gap="1rem">
            <Typography
              color={isCustomerEnable && "GrayText"}
              fontWeight={"bold"}
              variant="body2"
            >
              Disabled
            </Typography>
            <IOSSwitch
              checked={isCustomerEnable}
              onClick={() => {
                setIsCustomerEnable(!isCustomerEnable);
              }}
            />
            <Typography
              color={!isCustomerEnable ? "GrayText" : ""}
              fontWeight={"bold"}
              variant="body2"
            >
              Active
            </Typography>
          </Box>
        </Grid>

        {isCustomerEnable && (
          <Grid item mt={2} xs={12}>
            <Typography fontWeight={600}>Campaign Categories</Typography>
            <FormControl component="fieldset">
              <FormGroup color={grey[700]}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={categories.personal}
                          onChange={handleCampaignStatusChange}
                          name="personal"
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color={grey[700]}
                        >
                          Personal (B'day, Anniversary)
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={categories.festival}
                          onChange={handleCampaignStatusChange}
                          name="festival"
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color={grey[700]}
                        >
                          Festival (Holi, Diwali, etc.)
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={categories.specialDays}
                          onChange={handleCampaignStatusChange}
                          name="specialDays"
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color={grey[700]}
                        >
                          Special Days (Men's Day, Mother's Day, etc.)
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={categories.marketing}
                          onChange={handleCampaignStatusChange}
                          name="marketing"
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color={grey[700]}
                        >
                          Marketing Messages
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        {/* {customHeaders?.length ? (
          <>
            <Grid xs={12} mt={3}>
              <Typography fontWeight={600}>Custom Fields</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="body2"
                color={grey[500]}
                fontWeight={600}
                width={"200px"}
                flexShrink={0}
              >
                Custom Field Headers
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body2" color={grey[500]} fontWeight={600}>
                Custom Field Values
              </Typography>
            </Grid>
          </>
        ) : null} */}
      </Grid>
      <Box display={"flex"} gap={2} my={5}>
        <Button onClick={handleClose} variant="outlined" color="error">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" disabled={isExist}>
          Save
        </Button>
      </Box>

      <ConfirmationDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirmation={close}
        title="Warning"
        content={
          "You have unsaved changes. Are you sure you want to leave without saving?"
        }
      />
    </InternalPage>
  );
};

/**
 * @typedef {object} selectionInput
 * @property {string} [placeholder]
 * @property {string} [value]
 * @property {(value: string | null) => void} onChange
 * @property {boolean} [useSelect]
 * @property {boolean} [disabled]
 * @property {import('@mui/material').TextFieldProps} [OtherProps]
 */
/**
 *
 * @param {selectionInput & {
 *  lable: string,
 *  value: string,
 *  onLableInputChange?: (newValue: string) => void,
 *  required?: boolean,
 *  otherInput?: selectionInput,
 * }} props
 * @returns
 */
function DataInputRow({
  lable,
  value,
  onChange,
  placeholder,
  required,
  otherInput,
  disabled,
  OtherProps,
}) {
  return (
    <>
      <Grid
        item
        sm={3}
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "200px",
          flexGrow: "0",
        }}
      >
        <Typography variant="body2" fontWeight={500}>
          {lable}
        </Typography>
        {required && (
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{
              whiteSpace: "pre-wrap",
              color: (theme) => theme.palette.primary.main,
            }}
          >{` (Required)`}</Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={9} display={"flex"} gap={"10px"}>
        <TextField
          size="small"
          value={value || ""}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          {...OtherProps}
          sx={{
            my: "8px",
            width: "200px",
          }}
        />
        {otherInput && (
          <>
            <TextField
              size="small"
              value={otherInput.value}
              placeholder={otherInput.placeholder}
              onChange={(e) => otherInput.onChange(e.target.value)}
              disabled={otherInput.disabled}
              sx={{
                my: "8px",
                width: "200px",
              }}
            />
          </>
        )}
      </Grid>
    </>
  );
}

/**
 *
 * @param {selectionInput & {
 *  lable: string,
 *  value: string,
 *  onLableInputChange?: (newValue: string) => void,
 *  required?: boolean,
 *  info?: string,
 *  useSelect?: boolean,
 *  options: Array<string>,
 * }} props
 * @returns
 */
function DataSelectionRow({
  lable,
  options,
  value,
  onChange,
  placeholder,
  required,
  info,
  useSelect,
}) {
  return (
    <>
      <Grid
        item
        sm={3}
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" fontWeight={500}>
          {lable}
        </Typography>
        {required && (
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{
              color: (theme) => theme.palette.primary.main,
              whiteSpace: "pre",
            }}
          >{` (Required)`}</Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={9}
        display={"flex"}
        gap={"10px"}
        alignItems={"center"}
      >
        {useSelect ? (
          <Select
            value={value}
            label="Age"
            onChange={(e) => onChange(e.target.value)}
          >
            {options.map((option) => {
              return <MenuItem value={option}>{option}</MenuItem>;
            })}
          </Select>
        ) : (
          <Autocomplete
            options={options}
            value={value}
            onChange={(e, newValue) => onChange(newValue)}
            sx={{
              my: "8px",
              width: "200px",
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder={placeholder} />
            )}
          />
        )}
        {info && (
          <Typography color={"primary"} variant="body2" fontStyle={"italic"}>
            {info}
          </Typography>
        )}
      </Grid>
    </>
  );
}

export default EditCreatePage;
