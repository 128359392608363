//@ts-check

import React from 'react';
import DrawerAppBar from '../components/DrawerAppBar';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';

export default function SharedPublic() {
  return (
    <div>
      <DrawerAppBar />
      <Outlet />
      <Footer />
    </div>
  );
}
