//@ts-check

import React from "react";

import { Box, Container, Typography } from "@mui/material";
import { useEffect } from "react";

export default function TermsOfServicePage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const name = "ChatEngage";

  return (
    <Box marginTop={16} marginBottom={16}>
      <Container maxWidth="md">
        <Typography fontWeight={700} variant="h2" align="center" marginTop={4}>
          Terms of Service
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          These Terms of Service govern your use of the website located at
          www.chatengage.in, any other subdomain of {name} and other sites we
          own and operate.
          <br />
          <br />
          By accessing any website of {name}, you agree to abide by these Terms
          of Service and to comply with all applicable laws and regulations. If
          you do not agree with these Terms of Service, you are prohibited from
          using or accessing this website or using any other services provided
          by {name}.
          <br />
          Device Data
          <br />
          We, {name}, reserve the right to review and amend any of these Terms
          of Service at our sole discretion. Upon doing so, we will update this
          page. Any changes to these Terms of Service will take effect
          immediately from the date of publication.
          <br />
          <br />
          These Terms of Service were last updated on 3 June 2023.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Limitations of Use
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          By using this website, you warrant on behalf of yourself, your users,
          and other parties you represent that you will not:
          <br />
          <br />
          <ol>
            <li>
              modify, copy, prepare derivative works of, decompile, or reverse
              engineer any materials and software contained on this website;
            </li>
            <li>
              remove any copyright or other proprietary notations from any
              materials and software on this website;
            </li>
            <li>
              transfer the materials to another person or “mirror” the materials
              on any other server;
            </li>
            <li>
              knowingly or negligently use this website or any of its associated
              services in a way that abuses or disrupts our networks or any
              other service {name} provides;
            </li>
            <li>
              use this website or its associated services to transmit or publish
              any harassing, indecent, obscene, fraudulent, or unlawful
              material;
            </li>
            <li>
              use this website or its associated services in violation of any
              applicable laws or regulations;
            </li>
            <li>
              use this website in conjunction with sending unauthorized
              advertising or spam;
            </li>
            <li>
              harvest, collect, or gather user data without the user’s consent;
              or
            </li>
            <li>
              use this website or its associated services in such a way that may
              infringe the privacy, intellectual property rights, or other
              rights of third parties.
            </li>
          </ol>
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Intellectual Property
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          The intellectual property in the materials contained in this website
          are owned by or licensed to {name} and are protected by applicable
          copyright and trademark law. We grant our users permission to download
          one copy of the materials for personal, non-commercial transitory use.
          <br />
          <br />
          This constitutes the grant of a license, not a transfer of title. This
          license shall automatically terminate if you violate any of these
          restrictions or the Terms of Service, and may be terminated by
          {name} at any time.
          <br />
          <br />
          By using this website, you warrant on behalf of yourself, your users,
          and other parties you represent that you will not:
        </Typography>
        <Typography fontWeight={700} variant="h5" marginTop={4}>
          User-Generated Content
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          You retain your intellectual property ownership rights over content
          you submit to us for publication on our website. We will never claim
          ownership of your content, but we do require a license from you in
          order to use it.
          <br />
          <br />
          When you use our website or its associated services to post, upload,
          share, or otherwise transmit content covered by intellectual property
          rights, you grant to us a non-exclusive, royalty-free, transferable,
          sub-licensable, worldwide license to use, distribute, modify, run,
          copy, publicly display, translate, or otherwise create derivative
          works of your content in a manner that is consistent with your privacy
          preferences and our Privacy Policy.
          <br />
          <br />
          The license you grant us can be terminated at any time by deleting
          your content or account. However, to the extent that we (or our
          partners) have used your content in connection with commercial or
          sponsored content, the license will continue until the relevant
          commercial or post has been discontinued by us.
          <br />
          <br />
          You give us permission to use your username and other identifying
          information associated with your account in a manner that is
          consistent with your privacy preferences, and our Privacy Policy.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Liability
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Our website and the materials on our website are provided on an 'as
          is' basis. To the extent permitted by law, {name} makes no warranties,
          expressed or implied, and hereby disclaims and negates all other
          warranties including, without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property, or other violation of
          rights.
          <br />
          <br />
          In no event shall {name} or its suppliers be liable for any
          consequential loss suffered or incurred by you or any third party
          arising from the use or inability to use this website or the materials
          on this website, even if {name} or an authorized representative has
          been notified, orally or in writing, of the possibility of such
          damage.
          <br />
          <br />
          In the context of this agreement, “consequential loss” includes any
          consequential loss, indirect loss, real or anticipated loss of profit,
          loss of benefit, loss of revenue, loss of business, loss of goodwill,
          loss of opportunity, loss of savings, loss of reputation, loss of use
          and/or loss or corruption of data, whether under statute, contract,
          equity, tort (including negligence), indemnity, or otherwise.
          <br />
          <br />
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </Typography>
        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Accuracy of Materials
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          The materials appearing on our website are not comprehensive and are
          for general information purposes only. {name} does not warrant or make
          any representations concerning the accuracy, likely results, or
          reliability of the use of the materials on this website, or otherwise
          relating to such materials or on any resources linked to this website.
        </Typography>
        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Links
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          {name} has not reviewed all of the sites linked to its website and is
          not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement, approval, or control
          by {name} of the site. Use of any such linked site is at your own risk
          and we strongly advise you make your own investigations with respect
          to the suitability of those sites.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Right to Terminate
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We may suspend or terminate your right to use our website and
          terminate these Terms of Service immediately upon written notice to
          you for any breach of these Terms of Service.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Severance
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Any term of these Terms of Service which is wholly or partially void
          or unenforceable is severed to the extent that it is void or
          unenforceable. The validity of the remainder of these Terms of Service
          is not affected.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Governing Law
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          These Terms of Service are governed by and construed in accordance
          with the laws of India. You irrevocably submit to the exclusive
          jurisdiction of the courts in that State or location.
        </Typography>
      </Container>
    </Box>
  );
}
