import React from 'react';
import { Box } from '@mui/material';
import sample from '../../../src/assets/img/sample.png';

function SampleCampaigns() {
  return (
    <Box
      id="campaigns"
      sx={{ backgroundColor: 'background.paper' }}
      style={{ scrollMarginTop: '60px' }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
          component="img"
          src={sample}
          alt="WhatsApp Marketing"
        />
      </Box>
    </Box>
  );
}

export default SampleCampaigns;
