//@ts-check

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chip,
  IconButton,
  Stack,
  Typography,
  capitalize,
  Box,
} from "@mui/material";
import {
  Edit,
  Delete,
  PersonAdd,
  CloudUpload,
  GroupAdd,
  Settings,
} from "@mui/icons-material";
import { ConfirmationDialog } from "../components/confirmationDialog";
import { dateToString, toTitleCase } from "../../util/dateFunction";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMany,
  getAllCustomer,
  getHeaders,
  setSelectedCustomer,
} from "../../slice/customerSlice";
import { blue, grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { ManageColumn } from "../components/ManageColumn";

export default function AllCustomer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allCustomer, isLoading, customHeaders, tempCustomHeaders } = useSelector(
    (store) => store.customer
  );
  const [isDeleteManyModelOpen, setIsDeleteManyDialogOpen] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleDeleteManyModalClose() {
    setIsDeleteManyDialogOpen(false);
    setSelectedRowsId([]);
  }
  async function handleDeleteMany() {
    //delete many logic
    dispatch(
      deleteMany({
        selectedCustomerIDs: [...selectedRowsId],
      })
    );
    setSelectedRowsId([]);
    setIsDeleteManyDialogOpen(false);
  }

  const handleEditClick = (rowData) => {
    // Open the edit modal and perform other actions
    console.log("Edit row:", rowData);
    navigate("./edit");
    dispatch(setSelectedCustomer(rowData));
  };

  useEffect(() => {
    dispatch(getAllCustomer({}));
  }, []);

  const commonColumns = [
    {
      field: "fullName",
      headerName: "Full Name",
      description: "Customer's Full Name",
      sortable: true,
      minWidth: 130,
      flex: 0.1,
      valueGetter: (rowData) => {
        const fullName = `${rowData.row.firstName || ""} ${
          rowData.row.lastName || ""
        }`;
        return capitalize(fullName);
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      description: "Customer's Phone Number",
      sortable: true,
      minWidth: 130,
      flex: 0.1,
      // valueGetter: (params) =>
      //   params.row.countryCode && params.row.phone
      //     ? `+${params.row.countryCode} ${params.row.phone}`
      //     : '',
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 130,
      flex: 0.1,
    },
    {
      field: "gender",
      headerName: "Gender",
      minWidth: 100,
      valueGetter: (rowData) => toTitleCase(rowData.row.gender),
    },
    { field: "profession", headerName: "Profession", minWidth: 130 },
    {
      field: "dob",
      headerName: "Birthday",
      description: "Customer's Date of Birth",
      sortable: false,
      minWidth: 100,
      flex: 0.1,
      valueGetter: (rowData) => dateToString(`${rowData.row.dob}`),
    },
    {
      field: "anniversary",
      headerName: "Anniversary",
      description: "Customer's Anniversary Date",
      sortable: false,
      minWidth: 100,
      flex: 0.1,
      valueGetter: (rowData) => dateToString(`${rowData.row.anniversary}`),
    },
  ];
  const endColumns = [
    {
      field: "status",
      headerName: "Status",
      description: "Customer's Status",
      //minWidth: 130,
      align: "left",
      renderCell: (rowData) => {
        const status = rowData.row.isEnabled ? rowData.row.status : "disable";
        let color = "";
        switch (status) {
          case "complete":
            color = "success";
            break;
          case "partial":
            color = "info";
            break;
          case "duplicate":
            color = "warning";
            break;
          case "missing":
            color = "error";
            break;
          case "disable":
            color = "grey";
            break;
          default:
            color = "default";
        }
        return (
          <Chip
            label={status}
            variant="filled"
            sx={{
              backgroundColor: (theme) =>
                color !== "grey"
                  ? theme.palette[color].light
                  : theme.palette[color][600],
              width: "80px",
              flexShrink: 0,
              marginRight: "30px",
              height: "30px",

              "& .MuiChip-label": {
                color: "white",
                fontSize: "12px",
              },
            }}
          />
        );
      },
    },
    {
      field: "edit",
      headerName: "Actions",
      align: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} style={{ margin: "0px 8px" }}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleEditClick(params.row);
              }}
              sx={{
                border: `1px solid ${blue["A700"]}`,
                color: blue["A700"],
                backgroundColor: blue[50],
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const allActiveHeaderName = ((tempCustomHeaders?.length ? tempCustomHeaders : customHeaders) || []).map(
    (header) => header.name
  );
  const activeCommonHeader = ((tempCustomHeaders?.length ? tempCustomHeaders : customHeaders) || [])
    .filter((header) => {
      return header.status && !header.isCustom;
    })
    .map((header) => header.name);
  const activeCustomHeader = ((tempCustomHeaders?.length ? tempCustomHeaders : customHeaders) || [])
    .filter((header) => header.status && header.isCustom)
    .map((header) => header.name);

  const mergedColumns = [
    ...commonColumns.filter((column) => {
      return activeCommonHeader.includes(column.field);
    }),
    ...activeCustomHeader.map((header) => {
      return {
        field: header,
        headerName: header,
        description: "Custom Header",
        minWidth: 100,
        flex: 0.1,
        valueGetter: (rowData) => rowData.row.customFieldData?.[header],
      };
    }),
  ];

  const arragedColumns = [
    ...mergedColumns.sort(
    (a, b) => allActiveHeaderName.indexOf(a.field) - allActiveHeaderName.indexOf(b.field)
  ), 
  ...endColumns
  ];

  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 5rem)",
      }}
    >
      <Box display="flex" gap={2} my={2} justifyContent={"space-between"}>
        <Typography variant="h5">Customers</Typography>
        <Box display="flex" gap={1}>
          <CustomIconButton
            title="Upload Customers"
            onClick={() => {
              navigate("./bulk-upload");
            }}
            Icon={<GroupAdd />}
          />
          <CustomIconButton
            title="Create Customer"
            onClick={() => navigate("./create")}
            Icon={<PersonAdd />}
          />
          {/* <CustomIconButton
            onClick={() => {
              navigate("./edit")
            }}
            disabled={selectedRowsId.length == 1 ? false : true}
            Icon={<Edit/>}
          /> */}
          <CustomIconButton
            title="Delete"
            onClick={() => setIsDeleteManyDialogOpen(true)}
            disabled={selectedRowsId.length ? false : true}
            color={"error"}
            Icon={<Delete />}
          />
          <CustomIconButton
            title="Settings"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            Icon={<Settings />}
          />
        </Box>
      </Box>

      <div style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
        <DataGrid
          rows={allCustomer}
          columns={arragedColumns}
          loading={isLoading}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 30, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(rowSelectedModel) => {
            setSelectedRowsId(rowSelectedModel);
          }}
        />
      </div>

      {/* delete confirmation dialog */}
      <ConfirmationDialog
        title="Warning"
        content={
          <Typography variant="body2">
            Deleting all customer will permanently remove their data from the
            database. This action cannot be undone. Proceed with caution.
          </Typography>
        }
        open={isDeleteManyModelOpen}
        onClose={handleDeleteManyModalClose}
        onConfirmation={handleDeleteMany}
      />

      <ManageColumn
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />
    </div>
  );
}

/**
 *
 * @param {{
 *   Icon: import("react").ReactElement;
 *   onClick: () => void;
 *   disabled?: boolean;
 *   title?: string
 *   color?: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
 * }} param0
 * @returns
 */
function CustomIconButton({ Icon, onClick, disabled, color, title }) {
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      color={color}
      title={title}
      size="small"
      sx={{
        border: "1px solid",
        borderColor: grey["300"],
        borderRadius: "5px",
      }}
    >
      {Icon}
    </IconButton>
  );
}
