//@ts-check

export function extractObjectFromAOOByProperty (propertyName, propertyValue, ArrayOfObject){
    for(let i=0; i<ArrayOfObject.length; i++){
        if(ArrayOfObject[i][propertyName] === propertyValue){
            return ArrayOfObject[i];
        }
    }
}

export function valueInArrayOfPropertyFromAOO (propertyName, ArrayOfObject){

    const arrayOfValue = ArrayOfObject.reduce((accumelator, object) => {
        accumelator.push(object[propertyName]);
        return accumelator;
    }, []);


    return arrayOfValue;
}


export function updateAOOwithAnotherAOO (originalAOO, AOOContainingUpdates, propertyUseToMap){
    const arrayOfValueOfMapingProperty = valueInArrayOfPropertyFromAOO(propertyUseToMap, AOOContainingUpdates);

    console.warn(arrayOfValueOfMapingProperty);
    return originalAOO.map((object) => {
        if( !arrayOfValueOfMapingProperty.includes(object[propertyUseToMap]) ){
            return object;
        }

        return extractObjectFromAOOByProperty(propertyUseToMap, object[propertyUseToMap], AOOContainingUpdates);
    })
}


export function isObjectSame(obj1, obj2) {
    if(obj1 === obj2){
        return true;
    }
    if(!obj1 || !obj2){
        return false;
    }
    return !Object.keys(obj1).some((key) => {
        return obj1[key] !== obj2[key]
    })
}