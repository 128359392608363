//@ts-check

import React from 'react';

import { Box, Container, Typography } from '@mui/material';

import { useEffect } from 'react';
export default function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  const name = 'ChatEngage';
  const web = 'www.chatengage.in ';
  return (
    <Box marginTop={16} marginBottom={16}>
      <Container maxWidth="md">
        <Typography fontWeight={700} variant="h2" align="center" marginTop={4}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Your privacy is important to us. It is {name}'s policy to respect your
          privacy and comply with any applicable law and regulation regarding
          any personal information we may collect about you, including across
          our website, {web} or any other subdomain of {name} and other sites we
          own and operate.
          <br />
          <br />
          Personal information is any information about you which can be used to
          identify you. This includes information about you as a person (such as
          name, address, and date of birth), your devices, payment details, and
          even information about how you use a website or online service.
          <br />
          <br />
          In the event our site contains links to third-party sites and
          services, please be aware that those sites and services have their own
          privacy policies. After following a link to any third-party content,
          you should read their posted privacy policy information about how they
          collect and use personal information. This Privacy Policy does not
          apply to any of your activities after you leave our site.
          <br></br>
          <br></br>
          This policy is effective as of 3 June 2023.
          <br></br>
          <br></br>
          Last updated: 3 June 2023
        </Typography>

        <Typography fontWeight={700} variant="h6" marginTop={4}>
          Information We Collect
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Information we collect falls into one of two categories: “voluntarily
          provided” information and “automatically collected” information.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          “Voluntarily provided” information refers to any information you
          knowingly and actively provide us when using or participating in any
          of our services and promotions.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          “Automatically collected” information refers to any information
          automatically sent by your devices in the course of accessing our
          products and services
        </Typography>

        <Typography fontWeight={700} variant="h6" marginTop={4}>
          Log Data
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          When you visit our website, our servers may automatically log the
          standard data provided by your web browser. It may include your
          device's Internet Protocol (IP) address, your browser type and
          version, the pages you visit, the time and date of your visit, the
          time spent on each page, and other details about your visit.
        </Typography>

        <Typography fontWeight={700} variant="h6" marginTop={4}>
          Device Data
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          When you visit our website or interact with our services, we may
          automatically collect data about your device, such as:
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <ol>
            <li>Operating System</li>
            <li>Unique device identifiers</li>
            <li>Device settings</li>
            <li>Geo-location data</li>
          </ol>
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          Data we collect can depend on the individual settings of your device
          and software. We recommend checking the policies of your device
          manufacturer or software provider to learn what information they make
          available to us
        </Typography>

        <Typography fontWeight={700} variant="h6" marginTop={4}>
          Personal Information
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We may ask for personal information — for example, when you subscribe
          to our newsletter or when you contact us — which may include one or
          more of the following:
        </Typography>
        <Typography variant="body1" color="grey" marginTop={2}>
          <ol>
            <li>Name</li>
            <li>Email</li>
            <li>Social media profiles</li>
            <li>Date of birth</li>
            <li>Phone/mobile number</li>
            <li>Home/mailing address</li>
          </ol>
        </Typography>

        <Typography fontWeight={700} variant="h6" marginTop={4}>
          User-Generated Content
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We consider “user-generated content” to be materials (text, image
          and/or video content) voluntarily supplied to us by our users for the
          purpose of publication, processing, or usage on our platform. All
          user-generated content is associated with the account or email address
          used to submit the materials.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Please be aware that any content you submit for the purpose of
          publication will be public after posting (and subsequent review or
          vetting process). Once published, it may be accessible to third
          parties not covered under this privacy policy.
        </Typography>

        <Typography fontWeight={700} variant="h6" marginTop={4}>
          Transaction Data
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Transaction data refers to data that accumulates over the normal
          course of operation on our platform. This may include transaction
          records, stored files, user profiles, analytics data and other
          metrics, as well as other types of information, created or generated,
          as users interact with our services.
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          Legitimate Reasons for Processing Your Personal Information
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We only collect and use your personal information when we have a
          legitimate reason for doing so. In which instance, we only collect
          personal information that is reasonably necessary to provide our
          services to you.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Security of Your Personal Information
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          When we collect and process personal information, and while we retain
          this information, we will protect it within commercially acceptable
          means to prevent loss and theft, as well as unauthorized access,
          disclosure, copying, use, or modification.
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          Although we will do our best to protect the personal information you
          provide to us, we advise that no method of electronic transmission or
          storage is 100% secure, and no one can guarantee absolute data
          security.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          You are responsible for selecting any password and its overall
          security strength, ensuring the security of your own information
          within the bounds of our services. For example, ensuring any passwords
          associated with accessing your personal information and accounts are
          secure and confidential.
        </Typography>
        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Collection and Use of Information
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We may collect personal information from you when you do any of the
          following on our website:
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          <ul>
            <li>Register for an account</li>
            <li>Purchase a subscription</li>
            <li>
              Enter any of our competitions, contests, sweepstakes, and surveys
            </li>
            <li>
              Sign up to receive updates from us via email or social media
              channels
            </li>
            <li>Use a mobile device or web browser to access our content</li>
            <li>
              Contact us via email, social media, or on any similar technologies
            </li>
            <li>When you mention us on social media</li>
          </ul>
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We may collect, hold, use, and disclose information for the following
          purposes, and personal information will not be further processed in a
          manner that is incompatible with these purposes:
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <ul>
            <li>
              to provide you with our platform's core features and services
            </li>
            <li>
              to enable you to customize or personalize your experience of our
              website
            </li>
            <li>to deliver products and/or services to you</li>
            <li>to contact and communicate with you</li>
            <li>
              for analytics, market research, and business development,
              including to operate and improve our website, associated
              applications, and associated social media platforms
            </li>
            <li>
              for advertising and marketing, including to send you promotional
              information about our products and services and information about
              third parties that we consider may be of interest to you
            </li>
            <li>When you mention us on social media</li>
            <li>to consider your employment application</li>
            <li>
              to enable you to access and use our website, associated
              applications, and associated social media platforms
            </li>
            <li>for internal record keeping and administrative purposes</li>
            <li>
              fto run competitions, sweepstakes, and/or offer additional
              benefits to you
            </li>
            <li>
              to comply with our legal obligations and resolve any disputes that
              we may have
            </li>
            <li>
              to attribute any content (e.g. posts and comments) you submit that
              we publish on our website
            </li>
            <li>
              to attribute any content (e.g. posts and comments) you submit that
              we publish on our website
            </li>
            <li>
              for security and fraud prevention, and to ensure that our sites
              and apps are safe, secure, and used in line with our terms of use
            </li>
            <li>
              for technical assessment, including to operate and improve our
              app, associated applications, and associated social media
              platforms
            </li>
          </ul>
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          We may combine voluntarily provided and automatically collected
          personal information with general information or research data we
          receive from other trusted sources. For example, If you provide us
          with your location, we may combine this with general information about
          currency and language to provide you with an enhanced experience of
          our site and service.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          How Long We Keep Your Personal Information
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We keep your personal information only for as long as we need to. This
          time period may depend on what we are using your information for, in
          accordance with this privacy policy. For example, if you have provided
          us with personal information as part of creating an account with us,
          we may retain this information for the duration your account exists on
          our system. If your personal information is no longer required for
          this purpose, we will delete it or make it anonymous by removing all
          details that identify you.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          However, if necessary, we may retain your personal information for our
          compliance with a legal, accounting, or reporting obligation or for
          archiving purposes in the public interest, scientific, or historical
          research purposes or statistical purposes.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Legitimate Reasons for Processing Your Personal Information
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We only collect and use your personal information when we have a
          legitimate reason for doing so. In which instance, we only collect
          personal information that is reasonably necessary to provide our
          services to you.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Children's Privacy
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We do not aim any of our products or services directly at children
          under the age of 13, and we do not knowingly collect personal
          information about children under 13.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Your Rights and Controlling Your Personal Information
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Your choice:
          </Box>
          By providing personal information to us, you understand we will
          collect, hold, use, and disclose your personal information in
          accordance with this privacy policy. You do not have to provide
          personal information to us, however, if you do not, it may affect your
          use of our website or the products and/or services offered on or
          through it.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Information from third parties:
          </Box>
          If we receive personal information about you from a third party, we
          will protect it as set out in this privacy policy. If you are a third
          party providing personal information about somebody else, you
          represent and warrant that you have such person’s consent to provide
          the personal information to us.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Marketing permission:
          </Box>
          If you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time by
          contacting us using the details below.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Access:
          </Box>
          You may request details of the personal information that we hold about
          you.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Correction:
          </Box>
          If you believe that any information we hold about you is inaccurate,
          out of date, incomplete, irrelevant, or misleading, please contact us
          using the details provided in this privacy policy. We will take
          reasonable steps to correct any information found to be inaccurate,
          incomplete, misleading, or out of date.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Non-discrimination:
          </Box>
          We will not discriminate against you for exercising any of your rights
          over your personal information. Unless your personal information is
          required to provide you with a particular service or offer (for
          example processing transaction data), we will not deny you goods or
          services and/or charge you different prices or rates for goods or
          services, including through granting discounts or other benefits, or
          imposing penalties, or provide you with a different level or quality
          of goods or services.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Downloading of Personal Information:
          </Box>
          We provide a means for you to download the personal information you
          have shared through our site. Please contact us for more information.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Notification of data breaches:
          </Box>
          We will comply with laws applicable to us in respect of any data
          breach.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Complaints:
          </Box>
          If you believe that we have breached a relevant data protection law
          and wish to make a complaint, please contact us using the details
          below and provide us with full details of the alleged breach. We will
          promptly investigate your complaint and respond to you, in writing,
          setting out the outcome of our investigation and the steps we will
          take to deal with your complaint. You also have the right to contact a
          regulatory body or data protection authority in relation to your
          complaint.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <Box component="span" fontWeight={500} color="black">
            Unsubscribe:
          </Box>
          To unsubscribe from our email database or opt-out of communications
          (including marketing communications), please contact us using the
          details provided in this privacy policy, or opt-out using the opt-out
          facilities provided in the communication. We may need to request
          specific information from you to help us confirm your identity.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Disclosure of Personal Information to Third Parties
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We do not aim any of our products or services directly at children
          under the age of 13, and we do not knowingly collect personal
          information about children under 13.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Use of Cookies
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We use “cookies” to collect information about you and your activity
          across our site. A cookie is a small piece of data that our website
          stores on your computer, and accesses each time you visit, so we can
          understand how you use our site. This helps us serve you content based
          on preferences you have specified.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Please refer to our Cookie Policy for more information.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Business Transfers
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          If we or our assets are acquired, or in the unlikely event that we go
          out of business or enter bankruptcy, we would include data, including
          your personal information, among the assets transferred to any parties
          who acquire us. You acknowledge that such transfers may occur, and
          that any parties who acquire us may, to the extent permitted by
          applicable law, continue to use your personal information according to
          this policy, which they will be required to assume as it is the basis
          for any ownership or use rights we have over such information.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Limits of Our Policy
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and policies
          of those sites, and cannot accept responsibility or liability for
          their respective privacy practices.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Changes to This Policy
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          At our discretion, we may change our privacy policy to reflect updates
          to our business processes, current acceptable practices, or
          legislative or regulatory changes. If we decide to change this privacy
          policy, we will post the changes here at the same link by which you
          are accessing this privacy policy.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          If the changes are significant, or if required by applicable law, we
          will contact you (based on your selected preferences for
          communications from us) and all our registered users with the new
          details and links to the updated or changed policy.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          If required by law, we will get your permission or give you the
          opportunity to opt in to or opt out of, as applicable, any new uses of
          your personal information.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Additional Disclosures for California Compliance (US)
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Under California Civil Code Section 1798.83, if you live in California
          and your business relationship with us is mainly for personal, family,
          or household purposes, you may ask us about the information we release
          to other organizations for their marketing purposes.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          To make such a request, please contact us using the details provided
          in this privacy policy with “Request for California privacy
          information” in the subject line. You may make this type of request
          once every calendar year. We will email you a list of categories of
          personal information we revealed to other organisations for their
          marketing purposes in the last calendar year, along with their names
          and addresses. Not all personal information shared in this way is
          covered by Section 1798.83 of the California Civil Code.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Do Not Track
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Some browsers have a “Do Not Track” feature that lets you tell
          websites that you do not want to have your online activities tracked.
          At this time, we do not respond to browser “Do Not Track” signals.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          We adhere to the standards outlined in this privacy policy, ensuring
          we collect and process personal information lawfully, fairly,
          transparently, and with legitimate, legal reasons for doing so.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Cookies and Pixels
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          At all times, you may decline cookies from our site if your browser
          permits. Most browsers allow you to activate settings on your browser
          to refuse the setting of all or some cookies. Accordingly, your
          ability to limit cookies is based only on your browser's capabilities.
          Please refer to the Cookies section of this privacy policy for more
          information.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          CCPA-permitted financial incentives
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          In accordance with your right to non-discrimination, we may offer you
          certain financial incentives permitted by the CCPA that can result in
          different prices, rates, or quality levels for the goods or services
          we provide.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          Any CCPA-permitted financial incentive we offer will reasonably relate
          to the value of your personal information, and we will provide written
          terms that describe clearly the nature of such an offer. Participation
          in a financial incentive program requires your prior opt-in consent,
          which you may revoke at any time.
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          California Notice of Collection
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          In the past 12 months, we have collected the following categories of
          personal information enumerated in the California Consumer Privacy
          Act:
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <ol>
            <li>
              Identifiers, such as name, email address, phone number account
              name, IP address, and an ID or number assigned to your account.
            </li>
            <li>
              Customer records, such as billing and shipping address, and credit
              or debit card data.
            </li>
            <li>
              Demographics, such as your age or gender. This category includes
              data that may qualify as protected classifications under other
              California or federal laws.
            </li>
            <li>
              Commercial information, such as products or services history and
              purchases.
            </li>
            <li>
              Audio or visual data, such as photos or videos you share with us
              or post on the service.
            </li>
            <li> Geolocation data.</li>
            <li>
              Inferences, such as information about your interests, preferences
              and favorites.
            </li>
          </ol>
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          For more information on information we collect, including the sources
          we receive information from, review the “Information We Collect”
          section. We collect and use these categories of personal information
          for the business purposes described in the “Collection and Use of
          Information” section, including to provide and manage our Service.
        </Typography>
        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Right to Know and Delete
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          <ol>
            <li>
              The categories of personal information we have collected about
              you;
            </li>
            <li>
              The categories of sources from which the personal information was
              collected;
            </li>
            <li>
              The categories of personal information about you we disclosed for
              a business purpose or sold;
            </li>
            <li>
              The categories of third parties to whom the personal information
              was disclosed for a business purpose or sold;
            </li>
            <li>
              The business or commercial purpose for collecting or selling the
              personal information; and
            </li>
            <li>
              The specific pieces of personal information we have collected
              about you.
            </li>
          </ol>
        </Typography>

        <Typography variant="body1" color="grey" marginTop={4}>
          If you are a California resident, you have rights to delete your
          personal information we collected and know certain information about
          our data practices in the preceding 12 months. In particular, you have
          the right to request the following from us:
        </Typography>

        <Typography fontWeight={700} variant="h5" marginTop={4}>
          Shine the Light
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          If you are a California resident, in addition to the rights discussed
          above, you have the right to request information from us regarding the
          manner in which we share certain personal information as defined by
          California's “Shine the Light” with third parties and affiliates for
          their own direct marketing purposes.
        </Typography>
        <Typography variant="body1" color="grey" marginTop={4}>
          To receive this information, send us a request using the contact
          details provided in this privacy policy. Requests must include
          “California Privacy Rights Request” in the first line of the
          description and include your name, street address, city, state, and
          ZIP code.
        </Typography>
      </Container>
    </Box>
  );
}