import React from 'react';
import { Box, Card, Avatar, Grid, Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserTie,
  faMessage,
  faArrowUpRightDots,
} from '@fortawesome/free-solid-svg-icons';
const data = [
  {
    title: '2000+',
    subtitle: 'Advisors, Brokers and Sellers onboarded',
    useIcon: faUserTie,
  },
  {
    title: '20,000+',
    subtitle: 'Messages per customer per month',
    useIcon: faMessage,
  },
  {
    title: '75%',
    subtitle: 'Boost in Customer Engagements',
    useIcon: faArrowUpRightDots,
  },
];

function Stats() {
  const theme = useTheme();
  return (
    <Box bgcolor={'white'} paddingY={6}>
      <Container maxWidth="lg">
        <Box>
          <Typography
            variant={'h3'}
            sx={{ fontWeight: 700 }}
            align={'center'}
            gutterBottom
          >
            Grow your brand effortlessly!
          </Typography>
          <Typography
            variant={'body1'}
            align={'center'}
            color={'text.secondary'}
          >
            Automate communication with your customers through their favorite
            messaging app.
          </Typography>
        </Box>
        <Grid
          container
          display={'flex'}
          marginTop={4}
          spacing={4}
          marginBottom={4}
        >
          {data.map((item, i) => (
            <Grid
              key={i}
              item
              xs={12}
              md={4}
              display={'flex'}
              justifyContent={'center'}
            >
              <Box
                component={Card}
                padding={4}
                width={0.7}
                variant={'outlined'}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    component={Avatar}
                    width={50}
                    height={50}
                    marginBottom={2}
                    bgcolor={theme.palette.primary.main}
                    color={theme.palette.background.paper}
                  >
                    <FontAwesomeIcon icon={item.useIcon} size="1x" />
                  </Box>
                  <Typography
                    variant="h2"
                    align={'center'}
                    fontWeight={700}
                    color="primary.main"
                    gutterBottom
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    align={'center'}
                    component="p"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Stats;
