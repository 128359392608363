//@ts-check

import { Box } from "@mui/material";
import React, { useRef } from "react";
import { customFetch } from "../../util/axios";
import authHeader from "../../util/authToken";

export function Profile() {
  const [satatus, setStatus] = React.useState("loading");

  const statusRef = useRef("getting data");

  async function checkStatus() {
    while (statusRef.current == "getting data") {
      const res = await customFetch.get("api/v1/get_payment_info", authHeader());
      if (res.data.status != "PAYMENT_PENDING") {
        statusRef.current = "changed";
        setStatus(res.data.status);
      }

      await (async () => new Promise((res) => setTimeout(res, 10000)))();
    }
  }

  React.useEffect(() => {
    checkStatus();
  }, []);

  return (
    <>
      <Box
        height="100%"
        width="100%"
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
      >
        {`Payment status: ${satatus}`}
      </Box>
    </>
  );
}
