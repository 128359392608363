import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Container, Divider, Grid, Stack } from '@mui/material';
import logo from '../../../src/assets/img/CElogo.png';
import QrdCodeImage from '../../../src/assets/img/qr.png';

const pagesRoute = [
  {
    name: 'Privacy Policy',
    path: 'privacy-policy',
  },
  {
    name: 'Terms of Serivce',
    path: 'terms-of-service',
  },
  {
    name: 'Acceptable Use Policy',
    path: 'acceptablePolicy',
  },
  {
    name: 'Cookie Policy',
    path: 'cookie',
  },
];
export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',

        backgroundColor: '#fafafa',
      }}
    >
      <Divider />
      <Container maxWidth="lg" sx={{ padding: '8px', marginTop: '50px' }}>
        <Grid
          container
          justifyContent="space-between"
          sx={{ minHeight: '30vh', width: '100%' }}
          // columnGap="1rem"
        >
          <Grid item xs={12} md={6}>
            <Stack
              direction={'column'}
              // backgroundColor="red"
              sx={{ justifyContent: { xs: 'center', sm: 'center', md: '' } }}
            >
              <Stack
                direction="row"
                display={'flex'}
                justifyContent={{
                  xs: 'center',
                  sm: 'center',
                  md: 'flex-start',
                }}
                // backgroundColor="red"
              >
                <Box component="img" src={logo}></Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                >
                  <Typography fontWeight={500}>ChatEngage</Typography>
                  <Typography variant="body2" color="text.secondary">
                    your genius AI tool for <br />
                    WhatsApp marketing
                  </Typography>
                </Box>
              </Stack>

              <Box
                display="flex"
                gap="2rem"
                marginTop={4}
                marginBottom={4}
                // backgroundColor="red"
                justifyContent={{ xs: 'space-around' }}
              >
                <Typography variant="body2" color="text.secondary">
                  Campaigns
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Benefits
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Contact
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  FAQs
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stack
              justifyContent={{ xs: 'center', md: 'flex-end', sm: 'center' }}
              direction="row"
              gap="20px"
              margin={{ xs: '1rem', sm: '1rem', md: '0' }}
            >
              <Box
                component="img"
                src={QrdCodeImage}
                height="100px"
                width="100px"
              ></Box>
              <Box>
                <Typography
                  marginTop="5%"
                  fontWeight={500}
                  color="text.main.light"
                >
                  Launch ChatEngage
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Scan the QR code to connect <br />
                  with support team
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={12}>
            <Stack
              gap="10px"
              direction={{
                xs: 'column-reverse',
                sm: 'column-reverse',
                md: 'row',
              }}
              justifyContent="space-between"
              alignItems={{ xs: 'center', sm: 'center', md: 'end' }}
              // backgroundColor="orange"
              height="100%"
            >
              <Typography variant="body2" color="text.secondary">
                © Copyright {new Date().getFullYear()}. ChatEngage. All rights
                reserved.
              </Typography>
              <Box
                component="ul"
                display="flex"
                gap={{ sm: '3rem', xs: '1rem', md: '3rem' }}
                marginTop={{ sm: 3, xs: 3 }}
                sx={{
                  flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                  alignItems: { xs: 'center', sm: 'center' },
                }}
              >
                {pagesRoute.map((page) => (
                  <Box
                    component={Link}
                    to={page.path}
                    sx={{
                      color: 'text.secondary',
                      fontSize: '0.9rem',
                      cursor: 'pointer',
                      textDecoration: 'none',
                    }}
                    key={page.path}
                  >
                    {page.name}
                  </Box>
                ))}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
