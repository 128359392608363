//@ts-check
import React from 'react';

import Stats from '../components/stats';
import SampleCampaigns from '../components/sampleCampaigns';
import Benefits from '../components/benefits';
import Contact from '../components/contact';
import WhyUse from '../components/whyUse';
import Hero from '../components/hero';
import FAQ from '../components/faq';
import Industry from '../components/industry';

function HomePage() {
  return (
    <>
      <Hero />
      <WhyUse />
      <Stats />
      <SampleCampaigns />
      <Benefits />
      <Contact />
      <Industry />
      <FAQ />
    </>
  );
}

export default HomePage;
