//@ts-check

import React, { useState } from 'react';
import {
  Button,
  TextField,
  Box,
  Card,
  Avatar,
  Stack,
  Typography,
  CardContent,
  CardActions,
  Divider,
  ToggleButton,
  Alert,
  Tooltip,
  IconButton,
  Chip,
} from '@mui/material';
import { PageStyle } from '../../components/PageStyle';
import { AddPhotoAlternate, Close, Image, InsertPhoto, Mail, Schedule } from '@mui/icons-material';
import { customFetch } from '../../util/axios';
import { toast } from 'react-toastify';
import authHeader from '../../util/authToken';
import { ConfirmationDialog } from '../components/confirmationDialog';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { deepPurple, purple } from '@mui/material/colors';


const ScheduleMessageDialog = () => {
  const [isMsgScheduling, setIsMsgScheduling] = useState(false);
  const [selectedTime, setSelectedTime] = useState(dayjs(new Date()));
  const [message, setMessage] = useState('');
  const [image, setImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [reqInProgress, setReqInProgress] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleSchedule = () => {
    setSelectedTime(dayjs(new Date()));
    setIsMsgScheduling(!isMsgScheduling);
  };

  //function for opening and closeing confomation dialog
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };


  //for image selection
  const removeSelctedImage = async () => {
    setImage('');
    setImageUrl('');
    const fileInput = document.getElementById('fileInput');
    fileInput && (fileInput.value = null);
  }

  const handleImageChange = async (event) => {

    setImageUrl(event.target.files[0]);
    const reader = new FileReader();

    reader.onload = function (readerEvent) {
      const imageBase64String = readerEvent.target?.result;
      if(typeof imageBase64String !== 'string') return;
      setImage(imageBase64String);
    }

    reader.readAsDataURL(event.target.files[0]);
  }



  //scheduling campaign
  const scheduleCampaign = async () => {
    setReqInProgress(true);
    handleDialogClose();
    console.log('Scheduled message:', message);
    console.log('Selected time:', selectedTime);

    try {
      const res = await customFetch.post(
        '/api/v1/scheduled_camp',
        {
          message: message,
          image: image,
          scheduledTime: selectedTime,
        },
        {
          headers: {
            "Content-Type": 'multipart/form-data; ----',
            Authorization: authHeader().headers.authorization,
          }
        }
      );

      toast.success('Campaign created.', {
        position: 'bottom-left',
      });

      //reseting state
      setIsMsgScheduling(false);
      setMessage('');
      setReqInProgress(false);
      setImage('');
      setImageUrl('');
    } catch (err) {
      toast.error('Unable to create campaign.', {
        position: 'bottom-left',
      });
      console.error('Unable to create campaign', err);
      setReqInProgress(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          padding: {
            md: 4,
            sm: 2,
            xs: 1,
          },
          paddingBottom: '0px !important',
        }}
      >
        <Card
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 3,
            boxShadow: '0px 0px 10px 3px rgba(145, 158, 171, 0.1)',
            border: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <Box
              display="flex"
              gap={2}
              marginBottom={2}
              alignItems="center"
              flexDirection={{
                // md: '',
                sm: 'row',
                xs: 'column',
              }}
            >
              <Avatar
                sx={{
                  width: '3rem',
                  height: '3rem',
                  backgroundColor: (theme) => theme.palette.primary.main,
                }}
              >
                <Mail />
              </Avatar>
              <Stack
                alignItems={{
                  sm: 'initial',
                  xs: 'center',
                }}
                textAlign={{
                  sm: 'initial',
                  xs: 'center',
                }}
              >
                <Typography variant="body1" fontWeight={700}>
                  Sending to All Cusomter
                </Typography>
                <Typography variant="body2" color="GrayText" fontStyle="italic">
                  {`(Except for the customers that you have manually disabled)`}
                </Typography>
              </Stack>
            </Box>
            {
              imageUrl && 
              <Chip 
                label={imageUrl.name}
                sx={{
                  backgroundColor: deepPurple['50']
                }}
              />
            }
            <TextField
              fullWidth
              // rows={10}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              inputProps={{
                style: {
                  height: '100%',
                  overflow: 'auto',
                },
              }}
              sx={{
                height: '100%',
                '& .MuiInputBase-root': {
                  height: '100%',
                },
                '& fieldset': {
                  border: 'none',
                },
              }}
              multiline
              variant="outlined"
              placeholder="What you want to inform to your customers?"
            />
            <Stack direction={'row'}>
              <IconButton 
                sx={{
                  borderRadius: '50%',
                }}
                onClick={() => setIsImageDialogOpen(true)}
              >
                {
                  image ? <Image/>
                  : <AddPhotoAlternate/>
                  
                }
              </IconButton>
            </Stack>
          </CardContent>
          <Divider />
          <CardActions
            sx={{
              padding: '1.2rem',
              display: 'flex',
              flexGrow: 0,
              gap: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundColor: 'rgba(145, 158, 171, 0.1)',
              flexDirection: {
                md: 'row',
                xs: 'column',
              },
            }}
          >
            {isMsgScheduling ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  disablePast={true}
                  value={selectedTime}
                  onChange={(e) => e && setSelectedTime(e)}
                />
              </LocalizationProvider>
            ) : null}
            <Stack direction={'row'} gap={1}>
              <Tooltip title="Schedule">
                <ToggleButton
                  size="small"
                  onClick={toggleSchedule}
                  selected={isMsgScheduling}
                  value=""
                >
                  <Schedule />
                </ToggleButton>
              </Tooltip>
              <Button
                onClick={handleDialogOpen}
                variant="contained"
                size="medium"
                sx={{
                  // borderRadius: '500px',
                  width: '8rem',
                  color: 'white',
                }}
                disabled={reqInProgress || !(message || image)}
              >
                {reqInProgress
                  ? 'sending ...'
                  : isMsgScheduling
                  ? 'Schedule'
                  : 'Send Now'}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Box>

      {/* Dialog for confirmation of delete */}
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirmation={scheduleCampaign}
        title="Confirm Sending Time"
        content={
          <>
            <Typography variant="body1">
              {isMsgScheduling
                ? `Do you want to send this campaign at ${selectedTime
                    .locale('en-in')
                    .format('DD MMM YYYY hh:mm A')}`
                : `Do you want to sent this capmaign now ?`}
            </Typography>
            <br />
            {!isMsgScheduling && (
              <Alert severity="info">
                Click on <Schedule sx={{ verticalAlign: 'middle' }} /> to
                schedule this campaign for a later time.
              </Alert>
            )}
          </>
        }
      />


      <ConfirmationDialog
        open={isImageDialogOpen}
        onClose={() => setIsImageDialogOpen(false)}
        onConfirmation={() => setIsImageDialogOpen(false)}
        title='Select Image'
        cancelButtonText='cancel'
        confirmButtonText='Confirm'
        content={
          <>
            {image ? 
                <Box 
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  position='relative'
                  overflow='auto'
                >
                  <Box
                    component='img'
                    src={image}
                    height={300}
                  />
                  <IconButton 
                    size="small" 
                    onClick={removeSelctedImage}
                    color='error'
                    sx={{
                      marginLeft: '8px',
                      verticalAlign: "text-bottom",
                      border: '1px solid red',
                      borderColor: 'error.main',
                      flexShrink: 0,
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                  >
                    <Close fontSize="small"/>
                  </IconButton>
                </Box>
                :
                <Box 
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  height={300}
                >
                  <input
                    accept="image/*"
                    id="fileInput"
                    multiple={false}
                    type="file"
                    onChange={handleImageChange}
                    disabled={!!image}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="fileInput">
                    <Button
                      variant="contained"
                      component="span"
                      startIcon={<AddPhotoAlternate/>}
                    >
                      Choose file
                    </Button>
                  </label>
                </Box>
              }
          </>
        }
      />
    </>
  );
};

export default ScheduleMessageDialog;
