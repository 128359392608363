import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { loginUser } from "../../slice/userThunk";
import loginImg from "../../../src/assets/img/illustration_login.png";
import { Card, CircularProgress, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NeedHelp from "./login/NeedHelp";

export default function AuthPage() {
  const [userName, setUserName] = useState("");
  const [userPassWord, setUserPassWord] = useState();
  const { user, isLoading, loginError } = useSelector((store) => store.user);
  const [needHelp, setNeedHelp] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(loginUser({ phone: userName, password: userPassWord }));
  };
  React.useEffect(() => {
    if (user) {
      navigate("/user/dashboard");
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "80vh",
        marginTop: 12,
      }}
    >
      <Box
        sx={{
          width: {
            xs: "0px",
            sm: "0px",
            md: "70%",
          },
          display: "flex",
        }}
      >
        <Box
          component="img"
          sx={{
            height: "100%",
            width: "70%",
            margin: "auto",
          }}
          alt="The house from the offer."
          src={loginImg}
        />
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "40%",
          },
          display: "flex",
        }}
      >
        <Card
          sx={{
            width: "360px",
            minHeight: "60vh",
            display: "flex",
            boxShadow: "-24px 24px 72px -8px rgba(145, 158, 171, 0.24);",
            margin: "auto",
          }}
          // elevation={10}
        >
          {needHelp && <NeedHelp setNeedHelp={setNeedHelp} />}

          {!needHelp && (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 30,
                width: "100%",
              }}
              onSubmit={handleSubmit}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                marginTop={2}
                marginBottom={8}
              >
                <Typography variant="h3">Login!</Typography>
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      backgroundColor: "#f6f7f8",
                      borderRadius: "10px",
                    }}
                  >
                    <TextField
                      label="Phone number"
                      type="phone"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      fullWidth
                      variant="filled"
                      InputProps={{
                        style: {
                          backgroundColor: "transparent",
                        },
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#919EAB",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      my: 1,
                      backgroundColor: "#f6f7f8",
                      borderRadius: "10px",
                    }}
                  >
                    <TextField
                      label="Password"
                      value={userPassWord}
                      onChange={(e) => setUserPassWord(e.target.value)}
                      fullWidth
                      type="password"
                      variant="filled"
                      InputProps={{
                        style: {
                          backgroundColor: "transparent",
                        },
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#919EAB",
                          marginBottom: "15px",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    fontSize={"12px"}
                    fontStyle={"italic"}
                    height="14px"
                    mb={3}
                    sx={{
                      color: (theme) => theme.palette.error.main,
                    }}
                  >
                    {loginError &&
                      "Please provide a valid Phone Number and Password."}
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={isLoading}
                    startIcon={
                      isLoading && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                    }}
                    type="submit"
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </Button>
                  <Typography
                    variant="body2"
                    textAlign="right"
                    color="primary.main"
                  >
                    <Link
                      component={"span"}
                      underline="none"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setNeedHelp(true)}
                    >
                      Need help?
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </form>
          )}
        </Card>
      </Box>
    </Box>
  );
}
