export function stringToDate(date) {
  const dateObj =
    typeof date === "string"
      ? new Date(
          parseInt(String(date).slice(6)), // Year
          parseInt(String(date).slice(3, 5)) - 1, // Month (subtract 1 because months are zero-based)
          parseInt(String(date).slice(0, 2)) // Day
        )
      : date;

  return dateObj;
}

export function dateToString(date) {
  if (!date || date=='null') {
    return '';
  }
  const option = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const newDate = new Date(date);
  const localDateString = newDate.toLocaleString("en-IN", option);

  return localDateString;
}

export function cronToDate(cron) {
  if(!cron) throw new Error('cron time is not defined');
  const today = new Date();
  const cronTimeData = cron.split(' ');
  const year = today.getFullYear();
  const month = cronTimeData[3] == '*' ? today.getMonth() : parseInt(cronTimeData[3]) - 1;
  const monthDate = cronTimeData[2] == '*' ? today.getDate() : parseInt(cronTimeData[2]);
  const hour = cronTimeData[1] == '*' ? today.getHours() : parseInt(cronTimeData[1]);
  const minute = cronTimeData[0] == '*' ? today.getMinutes() : parseInt(cronTimeData[0]);
  const date = new Date(
    year,
    month,
    monthDate,
    hour,
    minute
  );

  return date;
}

export function toTitleCase(str) {
  if(!str) return '';

  return str.toLowerCase().replace(/(?:^|\s)\w/g, function(match) {
    return match.toUpperCase();
  });
}
