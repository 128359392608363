//@ts-check

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customFetch } from "../util/axios";
import authHeader from "../util/authToken";
import { updateAOOwithAnotherAOO } from "../util/objectFuction";


/**
 * @typedef {Object} allCustomer
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} rawName
 * @property {string} phone
 * @property {string} rawPhone
 * @property {string} countryCode
 * @property {string} email
 * @property {string} city
 * @property {string} profession
 * @property {string} dob
 * @property {string} anniversary
 * @property {string} gender
 * @property {string} createdBy
 * @property {string} status
 * @property {Object} campaignCatergoriesStatus
 * @property {Object} customFieldData
 */

/**
 * @typedef {Object} initialState
 * @property {Array<allCustomer> | []} allCustomer
 * @property {Array<string>} customHeaders
 * @property {Array<string>} tempCustomHeaders
 * @property {boolean} hasAllCustomer
 * @property {boolean} isLoading
 * @property {boolean} isUpdating
 * @property {allCustomer | null} selectedCustomer
 */

/**
 * @type {initialState}
 */
const initialState = {
  hasAllCustomer: false, //flag to know data is fatched already
  customHeaders: [],
  tempCustomHeaders: [],
  allCustomer: [],
  isLoading: false,
  isUpdating: false,
  selectedCustomer: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    resetCustomers: (state) => {
        state.allCustomer = initialState.allCustomer;
        state.hasAllCustomer = initialState.hasAllCustomer;
        state.isLoading = initialState.isLoading;
    },
    setSelectedCustomer: (state, {payload}) => {
        state.selectedCustomer = payload;
    },
    setTempCustomHeader: (state, {payload}) => {
        state.tempCustomHeaders = payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(
        getHeaders.pending,
        (state) => {
            state.isLoading = true;
        }
    )
    .addCase(
        getHeaders.fulfilled,
        (state, {payload}) => {
            state.customHeaders = payload.customHeaders;
            state.isLoading = false;
        }
    )
    .addCase(
        getHeaders.rejected,
        (state, {payload}) => {
            state.isLoading = false;
        }
    )
    .addCase(
        setHeaders.pending,
        (state) => {
            state.isUpdating = true;
        }
    )
    .addCase(
        setHeaders.fulfilled,
        (state, {payload}) => {
            state.customHeaders = payload.customHeaders;
            state.isUpdating = false;
        }
    )
    .addCase(
        setHeaders.rejected,
        (state, {payload}) => {
            state.isUpdating = false;
        }
    )
    .addCase(
        createCustomer.pending,
        (state) => {
            state.isUpdating = true;
        }
      )
      .addCase(createCustomer.fulfilled, (state, {payload}) => {
        const {createdCustomer, updatedCustomer} = payload;
        console.warn(payload);
        state.allCustomer.push(createdCustomer);
        if(updatedCustomer){
            state.allCustomer = 
            updateAOOwithAnotherAOO(
                state.allCustomer, 
                [updatedCustomer], 
                '_id'
            );
        }
      })
      .addCase(
        createCustomer.rejected, 
        (state, {payload}) => {
        console.error('createCustomer', payload);
      })
      .addCase(
        getAllCustomer.pending, 
        (state) => {
        if (!state.hasAllCustomer) {
          state.isLoading = true;
        }
      })
      .addCase(
        getAllCustomer.fulfilled, 
        (state, { payload }) => {
        state.allCustomer = payload.allCustomer;
        state.customHeaders = payload.customHeaders || [];
        state.isLoading=false;
        state.hasAllCustomer = true;
      })
      .addCase(
        getAllCustomer.rejected, 
        (state, { payload }) => {
        // console.error('getAllCustomer : ', payload);
      })
      .addCase(
        updateCustomer.pending,
        (state) => {
            state.isUpdating = true;
        }
      )
      .addCase(
        updateCustomer.fulfilled, 
        (state, { payload }) => {
        const {updatedCustomers} = payload;
        state.allCustomer = updateAOOwithAnotherAOO(state.allCustomer, updatedCustomers, '_id');
      })
      .addCase(
        updateCustomer.rejected, 
        (state, { payload }) => {
        console.error('updateCustomer : ', payload);
      })
      .addCase(
        deleteCustomerFromDB.pending,
        (state) => {
            state.isUpdating = true;
        }
      )
      .addCase(
        deleteCustomerFromDB.fulfilled, 
        (state, { payload }) => {
        const {deletedCustomer, updatedCustomer} = payload;
        const allCustomerAfterDelete = state.allCustomer
            .filter((customer) => customer._id !== deletedCustomer._id);

        if(updatedCustomer){
            state.allCustomer = updateAOOwithAnotherAOO(allCustomerAfterDelete, [updatedCustomer], '_id');
        } else {
            state.allCustomer = allCustomerAfterDelete;
        }
      })
      .addCase(
        deleteCustomerFromDB.rejected, 
        (state, { payload }) => {
        console.error('deleteCustomerFromDB : ', payload);
      })
      .addCase(
        deleteMany.pending,
        (state) => {
            state.idUpdating = true;
        }
      )
      .addCase(
        deleteMany.fulfilled, 
        (state, { payload }) => {
        const {deletedCustomerIDs, updatedCustomers, isAllDeleted} = payload;

        if(isAllDeleted){
            state.allCustomer = [];
            state.hasAllCustomer = false;
            return;
        } 

        const allCustomerAfterDelete = state.allCustomer
            .filter((customer) => !deletedCustomerIDs.includes(customer._id));

        state.allCustomer = updateAOOwithAnotherAOO(allCustomerAfterDelete, updatedCustomers, '_id');
      })
      .addCase(
        deleteMany.rejected, 
        (state, { payload }) => {
        console.error('daleteMany : ', payload);
      });
  },
});


export const getHeaders = createAsyncThunk(
    "header/get",
    async (payload, thunkAPI) => {
        try{
            const headerRes = await customFetch.get("api/v1/customer/customHeaders", authHeader());
            return {
                customHeaders: headerRes.data.headers,
            }
        } catch (err) {
            console.log('Cant load table Headers');
            return thunkAPI.rejectWithValue(err.response.data.msg || err.message);
        }
    }
)


export const setHeaders = createAsyncThunk(
    "header/set",
    async (payload, thunkAPI) => {
        try{
            const headerRes = await customFetch.post("api/v1/customer/customHeaders", {
                tableHeaders: payload,
            }, authHeader());
            return {
                customHeaders: payload,
            }
        } catch (err) {
            console.log('Cant set table Headers');
            return thunkAPI.rejectWithValue(err.response.data.msg || err.message);
        }
    }
)




export const createCustomer = createAsyncThunk(
    "customer/create",
    async (payload, thunkAPI) => {
        try{
            const {
                firstName,
                lastName,
                phone,
                countryCode,
                email,
                city,
                profession,
                dob,
                anniversary,
                gender,
                status,
                isEnabled,
                campaignCategoriesStatus,
                customFieldData,
            } = payload;

            const res = await customFetch.post(`api/v1/customer/`, {
                firstName,
                lastName,
                phone,
                countryCode,
                email,
                city,
                profession,
                dob,
                anniversary,
                gender,
                status,
                isEnabled,
                campaignCategoriesStatus,
                customFieldData,
            }, authHeader());

            return {
                createdCustomer: res.data.createdCustomer,
                updatedCustomer: res.data.updatedCustomer ? res.data.updatedCustomer : null,
            }; //return object with property createdCustomer and updatedCustomer
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data.msg || err.message);
        }
    }
)


export const getAllCustomer = createAsyncThunk(
    "customer/getAll",
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState();
        try {
            const {refresh} = payload;

            if(!refresh && state.customer.hasAllCustomer){
                throw new Error('Customer Allready fatched');
            }
            const allCustomerRes = await customFetch.get("api/v1/customer/", authHeader());
            const headerRes = await customFetch.get("api/v1/customer/customHeaders", authHeader());


            return {
                allCustomer: allCustomerRes.data.allCustomer,
                customHeaders: headerRes.data.headers,
            }
            // return allCustomerRes.data; //contain object with property allCustomer 

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.msg || error.message);
        }
    }
);

export const updateCustomer = createAsyncThunk(
    "customer/update",
    async (payload, thunkAPI) => {
        try {
            const {
                _id,
                firstName,
                lastName,
                phone,
                countryCode,
                email,
                city,
                profession,
                dob,
                anniversary,
                gender,
                isEnabled,
                campaignCategoriesStatus,
                customFieldData
            } = payload;

            const resp = await customFetch.patch(`api/v1/customer/${_id}`, 
            {
                _id,
                firstName,
                lastName,
                phone,
                countryCode,
                email,
                city,
                profession,
                dob,
                anniversary,
                gender,
                isEnabled,
                campaignCategoriesStatus,
                customFieldData
            }, 
            authHeader());

            return  {
                ...resp.data,
            } ; //contain object with property updatedCustomers
            //updatedCustomer is list of all affected customer
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.msg);
        }
    }
);

export const deleteCustomerFromDB = createAsyncThunk(
    "customer/delete",
    async (payload, thunkAPI) => {
        try {
            const {_id} = payload;
            const resp = await customFetch.delete(`api/v1/customer/${_id}`, authHeader());
            return resp.data; //contain object with two property deletedCustomer updatedCustomer
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.msg);
        }
    }
);

export const deleteMany = createAsyncThunk(
    "customer/deleteMany",
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState();
        console.warn('customer slice', state);
        try {
            const {selectedCustomerIDs, deleteAll} = payload;
            const allCustomer = state.customer.allCustomer;

            if(
                deleteAll 
                || selectedCustomerIDs.length === allCustomer.length
            ){
                const resp = await customFetch.post(`api/v1/customer/deleteMany`, {
                    deleteAll: true,
                }, authHeader());

                return resp.data; 
            }

            const selectedCustomerPhones = allCustomer.reduce((accumelator, customer) => {
                if(
                    selectedCustomerIDs.includes(customer._id)
                    && !accumelator.includes(customer.phone)
                ){ accumelator.push(customer.phone) };

                return accumelator;
            }, []);

            const resp = await customFetch.post(`api/v1/customer/deleteMany`, {
                customerIDArray: selectedCustomerIDs,
                customerPhoneArray: selectedCustomerPhones
            }, authHeader());

            return resp.data; //contain two property deletedCustomerIDs updatedCustomers
            //deletedCustomerIds is array of ids of deleted customers
            //updatedCusotmers is array of updated customers
        } catch (error) {
            if(error.message){
                console.error(error);
            }
            return thunkAPI.rejectWithValue(error.response.data.msg);
        }
    }
);



export const { resetCustomers, setSelectedCustomer, setTempCustomHeader } = customerSlice.actions;
export default customerSlice.reducer;
