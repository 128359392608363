//@ts-check

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetDispatch from './util/getDispatch';
import SharedPublic from './public/pages/SharedPublic';
import HomePage from '../src/public/pages/HomePage';
import AuthPage from '../src/public/pages/AuthPage';
import TermsOfServicePage from '../src/public/pages/footer/TermsOfServicePage';
import PrivacyPolicyPage from '../src/public/pages/footer/PrivacyPolicyPage';
import CookiePolicyPage from '../src/public/pages/footer/CookiePolicyPage';
import AcceptableUsePage from '../src/public/pages/footer/AcceptableUsePage';
import NavbarDashBoard from './private/components/NavbarDashBoard';
import ProtectedRoute from './private/components/ProtectedRoute';
import Dashboard from './private/pages/Dashboard';
import AllCustomer from './private/pages/AllCustomer';
import ScheduleMessageDialog from './private/pages/ComposeMsg';
import PresetCampaignPage from './private/pages/CampaignPage';
import ScheduledCampaignPage from './private/pages/ScheduledCampaign';
import ConnectToWhatsApp from './private/pages/ConnectToWhatsApp';
import UserProfilePage from './private/pages/UserProfilePage';
import ErrorPage from './public/pages/ErrorPage';
import Feed from './private/pages/Feed';
import BulkUpload from './private/pages/bulkUpload';
import EditCreatePage from './private/pages/EditCreatePage';
import PricingGridTest from './public/components/pricingTest';
import { Profile } from './private/pages/profile';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* public */}
        <Route path="/" element={<SharedPublic />}>
          <Route index element={<HomePage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/cookie" element={<CookiePolicyPage />} />
          <Route path="/acceptablePolicy" element={<AcceptableUsePage />} />
        </Route>
        {/* secure */}
        <Route
          path="/user"
          element={
            <ProtectedRoute>
              <NavbarDashBoard />
            </ProtectedRoute>
          }
        >
          <Route path='profile' element={<Profile/>} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path="all-customer">
            <Route index element={<AllCustomer/>} />
            <Route path='bulk-upload' element={<BulkUpload/>} />
            <Route path='edit' element={<EditCreatePage edit/>} />
            <Route path='create' element={<EditCreatePage />} />
          </Route>
          <Route path="compose" element={<ScheduleMessageDialog />} />
          <Route path="presetcampaign" element={<PresetCampaignPage />} />
          <Route path="scheduledcampaign" element={<ScheduledCampaignPage />} />
          <Route path="connect-whatsApp" element={<ConnectToWhatsApp />} />
          <Route path="user-profile" element={<UserProfilePage />} />
          <Route path="feed" element={<Feed />} />
          <Route path="pricing" element={<PricingGridTest/>} />
        </Route>
        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
      <ToastContainer
        position="bottom-left"
        toastClassName="custom-toast-container"
      />
      <GetDispatch />
    </BrowserRouter>
  );
}

export default App;
