//@ts-check

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaders, setTempCustomHeader } from "../../slice/customerSlice";
import { Add, Check, Close, DragIndicator } from "@mui/icons-material";
import { displayHeaderLabel } from "../../constants";

export function ManageColumn({ anchorEl, onClose }) {
  const { customHeaders, tempCustomHeaders } = useSelector((store) => store.customer);
  const newHeaderPrefrences = JSON.parse(JSON.stringify(tempCustomHeaders));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Array.isArray(customHeaders)) {
      return;
    }
    setNewHeaderPrefrences([...customHeaders]);
  }, [customHeaders]);

  function setNewHeaderPrefrences (headers) {
    dispatch(setTempCustomHeader(headers));
  }

  const draggingIndex = useRef(-1);
  const dragEnterElement = useRef(null);

  const resetState = () => {
    setNewHeaderPrefrences([...customHeaders]);
    draggingIndex.current = -1;
    dragEnterElement.current = null;
  };

  const handleHeaderSelection = (value, index) => {
    newHeaderPrefrences[index] = {
      ...newHeaderPrefrences[index],
      status: value,
    };
    setNewHeaderPrefrences([...newHeaderPrefrences]);
  };
  const addNewHeader = () => {
    newHeaderPrefrences.push({
      name: "",
      status: true,
      isCustom: true,
      isEditing: true,
    });
    setNewHeaderPrefrences([...newHeaderPrefrences]);
  };
  const handleHeaderEdit = (value, index) => {
    newHeaderPrefrences[index].name = value;
    setNewHeaderPrefrences([...newHeaderPrefrences]);
  };

  const savePrefrences = () => {
    dispatch(
      setHeaders([
        ...newHeaderPrefrences.map((header) => {
          return {
            name: header.name,
            status: header.status,
            isCustom: header.isCustom,
          };
        }),
      ])
    );

    resetState();
    onClose();
  };

  const handleCancel = () => {
    setNewHeaderPrefrences([]);
    resetState();
    onClose();
  }
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id="basic-menu"
      sx={{
        "& .MuiPopover-paper": {
            width: 250,
        }
      }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
    >
      {newHeaderPrefrences.map((header, i) => {
        return (
          <Box
            key={i}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              width: '100%',
              px: "16px",
              alignItems: "center",
              cursor: "move",
              '& [data-testid="DragIndicatorIcon"]': {
                display: "none",
              },
              "&:hover": {
                backgroundColor: "#9b70ff33",
                "& svg": {
                  display: "initial",
                },
              },
            }}
            draggable
            onDragStart={(e) => {
              draggingIndex.current = i;
              e.currentTarget.style.backgroundColor = "#9b70ff33";
            }}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => {
              dragEnterElement.current = e.currentTarget;
              e.currentTarget.style.backgroundColor = "#9b70ff33";
              const dragEnterIndex = i;

              if (draggingIndex.current == dragEnterIndex) {
                return;
              }

              if (dragEnterIndex == 0) {
                const draggingItem = newHeaderPrefrences.splice(
                  draggingIndex.current,
                  1
                )[0];
                newHeaderPrefrences.unshift(draggingItem);
                setNewHeaderPrefrences([...newHeaderPrefrences]);
                draggingIndex.current = 0;
                return;
              }
              const draggingItem = newHeaderPrefrences.splice(
                draggingIndex.current,
                1
              )[0];
              newHeaderPrefrences.splice(dragEnterIndex, 0, draggingItem);
              setNewHeaderPrefrences([...newHeaderPrefrences]);
              draggingIndex.current = dragEnterIndex;
            }}
            onDragLeave={(e) => {
              e.currentTarget.style.backgroundColor = "";
            }}
            onDragEnd={(e) => {
              if (dragEnterElement.current) {
                dragEnterElement.current.style.backgroundColor = "";
                dragEnterElement.current = null;
              }
              draggingIndex.current = -1;
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={header.status}
                  onChange={(e) => handleHeaderSelection(e.target.checked, i)}
                />
              }
              label={
                header.isEditing ? (
                  <TextField
                    sx={{
                        fontSize: theme => theme.typography.body2.fontSize,
                        my: '5px',
                    }}
                    inputProps={{
                        style: {
                            paddingTop: '5px',
                            paddingBottom: '5px',
                        }
                    }}
                    size="small"
                    value={header.name}
                    onChange={(e) => handleHeaderEdit(e.target.value, i)}
                  />
                ) : (
                  <Typography variant="body2" fontWeight={500}>
                      {header.isCustom ? header.name : displayHeaderLabel[header.name]}
                  </Typography>
                )
              }
            />
            <DragIndicator />
          </Box>
        );
      })}
      <Box display={"flex"} justifyContent={"center"}>
        <Button sx={{
            mt: '15px',
            mx: '5px',
            width: '100%'
        }}  variant="outlined" startIcon={<Add />} onClick={addNewHeader}>
            Add new Header
        </Button>
      </Box>
    <Divider sx={{
        mt: 1,
    }}/>
    <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
        <Button fullWidth color='error' onClick={handleCancel}>
            <Close/>
        </Button>
        <Button fullWidth color="success" onClick={savePrefrences}>
            <Check/>
        </Button>
    </Box>
    </Popover>
  );
}
