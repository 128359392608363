import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  colors,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoins,
  faStore,
  faBuilding,
  faBookOpen,
} from '@fortawesome/free-solid-svg-icons';
import { alpha } from '@mui/material/styles';

const data = [
  {
    title: 'Financial Advisors & Brokers',
    description:
      'Elevate Your Financial Services with ChatEngage - Enhance Client Communication, Streamline Operations, and Drive Growth for Financial Advisors, Mutual Fund Advisors, and Stock Brokers.',
    useIcon: faCoins,

    color: colors.blue[200],
  },
  {
    title: 'Retailers & Sellers',
    description:
      'Boost Sales, Improve Customer Service, and Drive Customer Loyalty with ChatEngage - Perfect for FnB, FnV, Retailers and Sellers.',
    useIcon: faStore,
    color: colors.purple[200],
  },
  {
    title: 'Real Estate Developers',
    description:
      'Transform Your Real Estate Business with ChatEngage - Streamline Sales, Improve Customer Experience, and Maximize Conversions for Real Estate Developers.',
    useIcon: faBuilding,

    color: colors.indigo[200],
  },
  {
    title: 'Educators & Coaching Institutes',
    description:
      'Revolutionize Education with ChatEngage - Engage Students, Simplify Communication, and Enhance Learning Experiences for Educators and Coaching Institutes.',
    useIcon: faBookOpen,
    color: colors.green[200],
  },
];

function Industry() {
  //   const theme = useTheme();
  return (
    <Box
      id="industry"
      bgcolor={'white'}
      style={{ scrollMarginTop: '60px' }}
      paddingBottom={5}
    >
      <Container maxWidth="lg">
        <Box sx={{ pt: 10, pb: 5 }}>
          <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>
            Solutions for Every Industry
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: 'text.secondary', mt: 2 }}
          >
            Industry specific solutions to grow your business and personally
            connect with customers
          </Typography>
        </Box>
        <Box marginBottom="120px">
          <Grid container spacing={6}>
            {data.map((item, i) => (
              <Grid key={i} item xs={12} md={6}>
                <Box
                  component={Card}
                  height={1}
                  bgcolor={alpha(item.color, 0.2)}
                  boxShadow={0}
                  sx={{
                    transform:
                      i % 2 === 1 ? { md: 'translateY(80px)' } : 'none',
                  }}
                >
                  <CardContent sx={{ padding: 4 }}>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      marginBottom={{ xs: 2, md: 4 }}
                    >
                      <FontAwesomeIcon
                        icon={item.useIcon}
                        size="3x"
                        style={{ color: alpha(item.color, 0.9) }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant={'h6'}
                        align="center"
                        gutterBottom
                        style={{ color: alpha(item.color, 0.9) }}
                        sx={{ fontWeight: 700 }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Industry;
