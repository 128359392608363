import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../slice/userThunk";
import wwebSlice from "../slice/wwebSlice";
import customerSlice from "../slice/customerSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    wweb: wwebSlice,
    customer: customerSlice,
  },
});
