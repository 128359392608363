import { io } from 'socket.io-client';
import { dispatch } from './getDispatch.js';
import { resetWwebStatus, updateWwebjsStatus } from '../slice/wwebSlice.js';
import { toast } from 'react-toastify';
import { BASE_URL } from '../constants.js';

let connectOnOnline = false;
const user = {
  _id: null,
};

const RECONNECTION_LIMIT = 10;
const socket = io(`${BASE_URL}`, {
  reconnectionAttempts: RECONNECTION_LIMIT,
  autoConnect: false,
}); //add option in second parameter an object

export default socket;

socket.on('connect', () => {
  dispatch(
    updateWwebjsStatus({
      socketConnected: true,
    })
  );
  console.log('socket connected with id', socket.id);
  socket.emit('connectUser', user._id, socket.id);
});

socket.on('wwebjs_ready', () => {
  dispatch(
    updateWwebjsStatus({
      ready: true,
    })
  );
  console.log('Whatsapp connected');
});

socket.on('qrGenerated', (qr) => {
  dispatch(
    updateWwebjsStatus({
      qr: qr,
    })
  );
  toast.info('Qr code genetared for whatsapp', {
    position: 'bottom-left',
  });
  console.log('whatsapp qr genereated');
});

socket.on('loading', (percent) => {
  dispatch(
    updateWwebjsStatus({
      loading: percent,
      qr: null,
    })
  );
  console.log('Whatsapp connection loading', percent);
});

socket.on('userDisconnected', () => {
  dispatch(resetWwebStatus());
  socket.disconnect();
});

socket.on('whatsapp_session_saved', () => {
  dispatch(
    updateWwebjsStatus({
      connected: true,
    })
  );
  console.log('whatsapp connected');
});

socket.on('notification', (message) => {
  toast.info(message, {
    position: 'bottom-right',
  });
});

socket.on('disconnect', (reason) => {
  dispatch(
    updateWwebjsStatus({
      socketConnected: false,
    })
  );
  console.warn(`Socket is disconnected because of : ${reason}`);
  if (reason === 'io server disconnect' || reason === 'io client disconnect') {
    console.log('socket will not connect');
    connectOnOnline = false;
  } else {
    socket.connect();
  }
});
socket.io.on('reconnect_attempt', (attempt) => {
  console.log('reconnecting attemp is ' + attempt);
});

//reconnecting when back online and was connected before
window.addEventListener('online', () => {
  if (connectOnOnline) {
    console.log('Back Online, Reconnecting socket ...');
    socket.connect();
  }
});

export function connectSocketForWwebjs(userId) {
  user._id = userId;
  socket.io.opts.query = {
    userId: userId,
  };

  connectOnOnline = true;
  console.log('connecting socket for UserId', userId);

  if (socket.connected) {
    socket.emit('connectUser', userId);
  }
  socket.connect();
}
