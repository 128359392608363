import React from 'react';
import { Box, Typography, Grid, Button, Container } from '@mui/material';
import hero from '../../../src/assets/img/hero.png';
import { phone, freeTrialMsg } from '../../constants.js';

function Hero() {
  return (
    <Box
      id="home"
      padding="8rem 2rem"
      marginTop={'40px'}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        overflow: 'hidden',
        //backgroundColor: 'red',
      }}
      style={{ scrollMarginTop: '60px' }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid
            item
            container
            xs={12}
            md={6}
            alignItems={'center'}
            display="flex"
            justifyContent="center"
          >
            <Box>
              <Box marginBottom={4}>
                <Typography variant="h1" color="primary.main">
                  Automatically send Personalized WhatsApp messages
                </Typography>
                <Typography marginTop={2} variant="h3">
                  Use the power of AI
                </Typography>
              </Box>

              <Box marginBottom={2}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ fontSize: '16px', fontWeight: '700' }}
                  href={`https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
                    freeTrialMsg
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Start for Free
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            display="flex"
            alignItems={'center'}
            justifyContent={'center'}
            xs={12}
            md={6}
            paddingBottom={4}
          >
            <Box
              component={'img'}
              height={1}
              width={1}
              marginTop={2}
              marginBottom={2}
              src={hero}
              alt="..."
              borderRadius={2}
              maxWidth={600}
              maxHeight={500}
              display="flex"
              sx={{
                //objectFit: 'cover',
                boxShadow: '19px 20px 0px 0 rgb(140 152 164 / 13%)',
                //filter: 'brightness(0.7)',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Hero;
