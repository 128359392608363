//@ts-check

import React from 'react';
import styled from "@emotion/styled";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowForwardIosSharp, ExpandMore } from '@mui/icons-material';




export const CustomAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  overflow: "hidden",
  "&:before": {
    display: "none",
  },
  border: "1px solid rgba(145, 158, 171, 0.25)",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),
  "&.Mui-expanded": {
    boxShadow: "0px 0px 10px 3px rgba(145, 158, 171, 0.1)",
    marginBottom: theme.spacing(5),
    "& .MuiAccordionSummary-root": {
      backgroundColor: "rgba(145, 158, 171, 0.1)",
    },
  },
  boxShadow: "0px 0px 10px 3px rgba(145, 158, 171, 0.1)",
}));



export const CustomAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ExpandMore />} {...props} />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "&:hover": {
    borderRadius: theme.spacing(1),
  },
}));



export const CustomAccordionDetails = styled((props) => (
   < AccordionDetails {...props}/>
))(({ theme }) => ({
  padding: `
    ${theme.spacing(1)} 
    ${theme.spacing(2)} 
    ${theme.spacing(0)} 
    ${theme.spacing(3)}
  `,
  "& svg": {
    width: theme.spacing(4),
  },
  borderTop: "1px solid rgba(145, 158, 171, 0.2)",
}));
