import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

export default function FaqSection() {
  const FaqDetails = [
    {
      question: 'What is ChatEngage?',
      ans: 'Chat Engage is a customer engagement tool designed to facilitate seamless communication between businesses and their customers through WhatsApp.',
    },
    {
      question: 'How does ChatEngage benefit businesses?',
      ans: 'Chat Engage helps businesses enhance customer engagement by providing a platform to connect with customers in real-time, automate messages, and run personalized campaigns.',
    },
    {
      question: 'Can I use ChatEngage for my small business?',
      ans: 'Absolutely! ChatEngage is specially designed for small business, enabling them to effectively engage with their customers on WhatsApp.',
    },
    {
      question: 'How does ChatEngage work?',
      ans: 'ChatEngage utilizes WhatsApp Web, combined with AI algorithms, to enable businesses to connect with customers on WhatsApp.',
    },

    {
      question: "Why isn't ChatEngage free?",
      ans: 'ChatEngage is not free to use due to server costs, resource expenses, and ongoing maintenance required to provide a reliable and feature-rich customer engagement tool. ',
    },
    {
      question: 'Is ChatEngage available 24/7?',
      ans: 'Yes, ChatEngage is available 24 hours a day, 7 days a week.',
    },
    {
      question: 'Is ChatEngage safe and secure to use?',
      ans: 'Yes, ChatEngage is safe and secure to use. All information and data is kept confidential and secure.',
    },
    {
      question: 'How can I get started with ChatEngage?',
      ans: 'To get started with Chat Engage, you can visit our website, and connect with us on WhatsApp. Our support team will help you through the onboarding process.',
    },
    {
      question: 'Are there any limits?',
      ans: "There is a fair-usage policy. Practicing wise and considerate usage ensures a smooth experience and maintains compliance with WhatsApp's guidelines.",
    },
  ];
  return (
    <Box
      id="faq"
      minHeight="60vh"
      padding="4rem 2rem"
      sx={{ backgroundColor: '#fafafa' }}
      style={{ scrollMarginTop: '60px' }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4">Frequently asked questions</Typography>
        <Typography variant="h6" margin="2% 0">
          If you have anything else you want to ask, reach out to us.
        </Typography>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          sx={{ marginTop: '5rem' }}
        >
          {FaqDetails.map((item, idx) => {
            return (
              <Grid item key={idx} xs={2} sm={4} md={4}>
                <Typography variant="body1" fontWeight="600">
                  {item.question}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {item.ans}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}
