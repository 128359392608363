import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faUserShield,
  faCheckDouble,
  faComments,
  faListCheck,
  faUserPen,
} from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';

const data = [
  {
    title: 'Customers’ Favorite App',
    subtitle:
      'Many customers prefer WhatsApp as the number one messaging platform.',
    useIcon: faHeart,
  },
  {
    title: 'End-To-End Encryption',
    subtitle:
      'WhatsApp is a secure application, making it the top business messaging app.',
    useIcon: faUserShield,
  },
  {
    title: '“Delivered” and “Read”',
    subtitle:
      'These two statuses allow us to optimize texts for high conversion and filter unreachable numbers.',
    useIcon: faCheckDouble,
  },
  {
    title: 'Two-Way Communication',
    subtitle:
      'Engage in two-way messaging with your customers and build a stronger relationship.',
    useIcon: faComments,
  },
  {
    title: 'Easy to Setup',
    subtitle: 'Simple and Free integration with WhatsApp.',
    useIcon: faListCheck,
  },
  {
    title: 'Personalization',
    subtitle: 'Human touch for all your outgoing messages.',
    useIcon: faUserPen,
  },
];
function Benefits() {
  const theme = useTheme();
  return (
    <Box
      id="benefits"
      bgcolor={'white'}
      style={{ scrollMarginTop: '60px' }}
      paddingBottom={5}
    >
      <Container maxWidth="lg">
        <Box sx={{ pt: 10, pb: 5 }}>
          <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>
            Benefits with us
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: 'text.secondary', mt: 2 }}
          >
            Seemlessly connect with customers on WhatsApp, fostering enhanced
            customer engagement and driving business growth
          </Typography>
        </Box>
        <Grid container spacing={4} display={'flex'}>
          {data.map((item, i) => (
            <Grid
              item
              xs={12}
              md={6}
              key={i}
              display={'flex'}
              justifyContent={'center'}
            >
              <Box paddingX={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent={'center'}
                  alignItems="center"
                  marginBottom={{ xs: 2, md: 4 }}
                >
                  {/* <Box component="img" src={item.image} width="250px"></Box> */}
                  <FontAwesomeIcon
                    icon={item.useIcon}
                    size="3x"
                    style={{ color: theme.palette.primary.main }}
                  />

                  <Box>
                    <Typography
                      variant={'h6'}
                      color="primary.main"
                      gutterBottom
                      sx={{ fontWeight: 700, marginTop: '20px' }}
                      align="center"
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="body1" color="grey" align="center">
                      {item.subtitle}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Benefits;
