//@ts-check

import React from "react";
import styled from "@emotion/styled";
import { Avatar } from "@mui/material";

const RectangularAvatar = styled((props) => (
  <Avatar variant="rounded" {...props} />
))(({ theme }) => ({
  width: "3.5rem",
  height: "3.5rem",
  borderRadius: theme.spacing(1),
  // backgroundColor: theme.palette.primary.main,
}));


export default RectangularAvatar;