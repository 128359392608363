import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { phone, freeTrialMsg } from '../../constants.js';
function Contact() {
  return (
    <Box
      id="contact"
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        flexDirection: 'column',
        justifyItems: 'center',
        backgroundColor: 'primary.main',
      }}
      style={{ scrollMarginTop: '60px' }}
    >
      <Box marginBottom={2} marginTop={8}>
        <Typography
          variant="h3"
          color="white"
          sx={{
            fontWeight: 700,
          }}
        >
          Talk to our experts
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <Typography variant="h1" color="white">
          70396 04776
        </Typography>
      </Box>
      <Box marginBottom={8}>
        <Button
          style={{ border: '2px solid white', color: 'white', fontWeight: 700 }}
          variant="outlined"
          size="large"
          href={`https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
            freeTrialMsg
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Get in touch for Free Trial
        </Button>
      </Box>
    </Box>
  );
}

export default Contact;
