//@ts-check

import React from "react";
import Box from "@mui/material/Box";
import { AppBar, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


/**
 * 
 * @param {import("react").PropsWithChildren<{
 *   heading: string,
 *   onClose?: () => void,
 * }>} props 
 * @returns 
 */
export function InternalPage({
    heading,
    onClose,
    children
}) {
    const navigate = useNavigate();
  return (
    <Stack>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {heading}
        </Typography>
        <IconButton
          onClick={onClose ? onClose : () => navigate("../")}
          size="large"
          edge="start"
          color="inherit"
          aria-label="close"
        >
          <Close
            sx={{
              color: "gray",
            }}
          />
        </IconButton>
      </AppBar>
      <Divider />
      <Box mt={'10px'}>
            {children}
      </Box>
    </Stack>
  );
}
